define('ir-app/templates/ir-mobile/chart-widgets/chart-widget', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1,"role","presentation");
            dom.setAttribute(el1,"class","tabsbr");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"role","tab");
            dom.setAttribute(el2,"data-toggle","tab");
            dom.setAttribute(el2,"class","font-large");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element50 = dom.childAt(fragment, [1, 1]);
            var morph0 = dom.createMorphAt(element50,0,0);
            var attrMorph0 = dom.createAttrMorph(element50, 'href');
            attribute(env, attrMorph0, element50, "href", concat(env, [get(env, context, "tabItem.ID")]));
            element(env, element50, context, "action", ["setChartPeriod", get(env, context, "tabItem")], {});
            content(env, morph0, context, "tabItem.DisplayName");
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
              inline(env, morph0, context, "global-search", [], {"enableContentSettings": false, "searchKey": get(env, context, "searchSymbol"), "closePopup": "closeSearchPopup", "openPopup": "showSearchPopup", "clickAction": "onSymbolSelected", "stopGlobalNotification": true, "exchangeList": get(env, context, "portalExchangeList"), "wkey": get(env, context, "wkey")});
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","symbol-comparison");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","full-width");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"id","label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3,"id","symbolSearchKey");
            dom.setAttribute(el3,"class","input-group full-width outline-none");
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            dom.setAttribute(el2,"class","full-width");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","modal-symbol");
            dom.setAttribute(el3,"style","display:table-cell; position: absolute;");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element48 = dom.childAt(fragment, [1]);
            var element49 = dom.childAt(element48, [1]);
            var morph0 = dom.createMorphAt(dom.childAt(element49, [1]),0,0);
            var morph1 = dom.createMorphAt(dom.childAt(element49, [3]),1,1);
            var morph2 = dom.createMorphAt(dom.childAt(element48, [3, 1]),1,1);
            content(env, morph0, context, "placeHolder");
            inline(env, morph1, context, "input", [], {"type": "text", "value": get(env, context, "searchSymbol"), "key-down": "showSearchPopup", "placeholder": get(env, context, "placeHolder"), "class": "full-width p-relative search-field outline-none no-padding"});
            block(env, morph2, context, "modal-popup", [], {"isEnabled": false, "id": "symbolSearch"}, child0, null);
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","checked ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element42 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element42,0,0);
                  element(env, element42, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element41 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element41,0,0);
                  element(env, element41, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "item.IsAdded")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element40 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element40,0,0);
                element(env, element40, context, "action", ["setChartStyle", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin-top-print margin34");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","chart-fig-btn text-align-center");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","dropdown");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"id","label");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4,"class","btn btn-default dropdown-toggle border-none btn-text-align");
              dom.setAttribute(el4,"type","button");
              dom.setAttribute(el4,"id","indicatorDropDown");
              dom.setAttribute(el4,"data-toggle","dropdown");
              dom.setAttribute(el4,"aria-haspopup","true");
              dom.setAttribute(el4,"aria-expanded","true");
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","stk-icon fa fa-caret-down");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("ul");
              dom.setAttribute(el4,"class","indicator-menu dropdown-menu y-scroll dropdown-set");
              dom.setAttribute(el4,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el4,"id","dropDwnIndicatorId");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin-top-print margin34");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","chart-fig-btn");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","dropdown");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"id","label");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4,"class","btn btn-default dropdown-toggle border-none btn-text-align");
              dom.setAttribute(el4,"type","button");
              dom.setAttribute(el4,"id","areaDropDown");
              dom.setAttribute(el4,"data-toggle","dropdown");
              dom.setAttribute(el4,"aria-haspopup","true");
              dom.setAttribute(el4,"aria-expanded","true");
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","stk-icon fa fa-caret-down");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("ul");
              dom.setAttribute(el4,"class","dropdown-menu y-scroll");
              dom.setAttribute(el4,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el4,"id","dropDwnArea");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, content = hooks.content, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element43 = dom.childAt(fragment, [1, 1, 1]);
              var element44 = dom.childAt(element43, [3]);
              var element45 = dom.childAt(fragment, [3, 1, 1]);
              var element46 = dom.childAt(element45, [3]);
              var morph0 = dom.createMorphAt(dom.childAt(element43, [1]),0,0);
              var morph1 = dom.createMorphAt(element44,1,1);
              var attrMorph0 = dom.createAttrMorph(element44, 'title');
              var morph2 = dom.createMorphAt(dom.childAt(element43, [5]),1,1);
              var morph3 = dom.createMorphAt(dom.childAt(element45, [1]),0,0);
              var morph4 = dom.createMorphAt(element46,1,1);
              var attrMorph1 = dom.createAttrMorph(element46, 'title');
              var morph5 = dom.createMorphAt(dom.childAt(element45, [5]),1,1);
              content(env, morph0, context, "app.lang.labels.indicators");
              attribute(env, attrMorph0, element44, "title", concat(env, [get(env, context, "app.lang.labels.selectInd")]));
              content(env, morph1, context, "app.lang.labels.selectInd");
              block(env, morph2, context, "each", [get(env, context, "getDisplayIndicators")], {"keyword": "item"}, child0, null);
              content(env, morph3, context, "app.lang.labels.chartType");
              attribute(env, attrMorph1, element46, "title", concat(env, [get(env, context, "app.lang.labels.chartStyleTitle")]));
              content(env, morph4, context, "chartStyle.DisplayName");
              block(env, morph5, context, "each", [get(env, context, "chartStyles")], {"keyword": "item"}, child1, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin34 stk-ver-margin margin-top-print range-res stk-height");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","chart-fig-btn text1");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"id","label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-group has-feedback");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4,"class","calendar-icon fa fa-calendar form-control-feedback");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin34 stk-ver-margin stk-btm-mgn margin-top-print range-res stk-height");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","chart-fig-btn");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"id","label");
            dom.setAttribute(el3,"style","color:#fff");
            var el4 = dom.createTextNode("Date Range");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","form-group has-feedback");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4,"class","calendar-icon fa fa-calendar form-control-feedback");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block, content = hooks.content, inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element47 = dom.childAt(fragment, [2, 1]);
            var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
            var morph1 = dom.createMorphAt(dom.childAt(element47, [1]),0,0);
            var morph2 = dom.createMorphAt(dom.childAt(element47, [3]),1,1);
            var morph3 = dom.createMorphAt(dom.childAt(fragment, [4, 1, 3]),1,1);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "unless", [get(env, context, "compareSymbolMode")], {}, child0, null);
            content(env, morph1, context, "app.lang.labels.dateRange");
            inline(env, morph2, context, "bootstrap-datepicker", [], {"readonly": "readonly", "language": get(env, context, "lan"), "placeholder": get(env, context, "app.lang.labels.from"), "class": "form-control date-picker form-style", "value": get(env, context, "fromDate"), "startDate": get(env, context, "oldestDate"), "endDate": "+0d", "autoclose": true, "todayHighlight": true});
            inline(env, morph3, context, "bootstrap-datepicker", [], {"readonly": "readonly", "language": get(env, context, "lan"), "placeholder": get(env, context, "app.lang.labels.to"), "class": "form-control date-picker form-style", "value": get(env, context, "toDate"), "autoclose": true, "startDate": get(env, context, "fromDate"), "endDate": "+0d", "todayHighlight": true, "orientation": "right"});
            return fragment;
          }
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","checked ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element35 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element35,0,0);
                  element(env, element35, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            var child1 = (function() {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.1",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createElement("a");
                  dom.setAttribute(el2,"href","#");
                  dom.setAttribute(el2,"class","ltr-text");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element34 = dom.childAt(fragment, [1, 0]);
                  var morph0 = dom.createMorphAt(element34,0,0);
                  element(env, element34, context, "action", ["setIndicator", get(env, context, "item")], {});
                  content(env, morph0, context, "item.DisplayName");
                  return fragment;
                }
              };
            }());
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [get(env, context, "item.IsAdded")], {}, child0, child1);
                return fragment;
              }
            };
          }());
          var child1 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element33 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element33,0,0);
                element(env, element33, context, "action", ["setChartStyle", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin-top-print margin34");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","chart-fig-btn text-align-center");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","dropdown");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"id","label");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4,"class","btn btn-default dropdown-toggle border-none btn-text-align");
              dom.setAttribute(el4,"type","button");
              dom.setAttribute(el4,"id","indicatorDropDown");
              dom.setAttribute(el4,"data-toggle","dropdown");
              dom.setAttribute(el4,"aria-haspopup","true");
              dom.setAttribute(el4,"aria-expanded","true");
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","stk-icon fa fa-caret-down");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("ul");
              dom.setAttribute(el4,"class","indicator-menu dropdown-menu y-scroll dropdown-set");
              dom.setAttribute(el4,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el4,"id","dropDwnIndicatorId");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin-top-print margin34");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","chart-fig-btn");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","dropdown");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"id","label");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4,"class","btn btn-default dropdown-toggle border-none btn-text-align");
              dom.setAttribute(el4,"type","button");
              dom.setAttribute(el4,"id","areaDropDown");
              dom.setAttribute(el4,"data-toggle","dropdown");
              dom.setAttribute(el4,"aria-haspopup","true");
              dom.setAttribute(el4,"aria-expanded","true");
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","stk-icon fa fa-caret-down");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("ul");
              dom.setAttribute(el4,"class","dropdown-menu y-scroll");
              dom.setAttribute(el4,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el4,"id","dropDwnArea");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, content = hooks.content, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element36 = dom.childAt(fragment, [1, 1, 1]);
              var element37 = dom.childAt(element36, [3]);
              var element38 = dom.childAt(fragment, [3, 1, 1]);
              var element39 = dom.childAt(element38, [3]);
              var morph0 = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
              var morph1 = dom.createMorphAt(element37,1,1);
              var attrMorph0 = dom.createAttrMorph(element37, 'title');
              var morph2 = dom.createMorphAt(dom.childAt(element36, [5]),1,1);
              var morph3 = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
              var morph4 = dom.createMorphAt(element39,1,1);
              var attrMorph1 = dom.createAttrMorph(element39, 'title');
              var morph5 = dom.createMorphAt(dom.childAt(element38, [5]),1,1);
              content(env, morph0, context, "app.lang.labels.indicators");
              attribute(env, attrMorph0, element37, "title", concat(env, [get(env, context, "app.lang.labels.selectInd")]));
              content(env, morph1, context, "app.lang.labels.selectInd");
              block(env, morph2, context, "each", [get(env, context, "getDisplayIndicators")], {"keyword": "item"}, child0, null);
              content(env, morph3, context, "app.lang.labels.chartType");
              attribute(env, attrMorph1, element39, "title", concat(env, [get(env, context, "app.lang.labels.chartStyleTitle")]));
              content(env, morph4, context, "chartStyle.DisplayName");
              block(env, morph5, context, "each", [get(env, context, "chartStyles")], {"keyword": "item"}, child1, null);
              return fragment;
            }
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("a");
                dom.setAttribute(el2,"href","#");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, get = hooks.get, element = hooks.element, content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element30 = dom.childAt(fragment, [1, 0]);
                var morph0 = dom.createMorphAt(element30,0,0);
                element(env, element30, context, "action", ["setChartInterval", get(env, context, "item")], {});
                content(env, morph0, context, "item.DisplayName");
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 margin34 margin-top-print stk-ver-margin stk-btm-mgn stk-height");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","chart-fig-btn interval-dropdown");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","dropdown");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              dom.setAttribute(el4,"id","label");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("button");
              dom.setAttribute(el4,"class","btn btn-default dropdown-toggle border-none single-line-text btn-text-align");
              dom.setAttribute(el4,"type","button");
              dom.setAttribute(el4,"id","minutesDropDown");
              dom.setAttribute(el4,"data-toggle","dropdown");
              dom.setAttribute(el4,"aria-haspopup","true");
              dom.setAttribute(el4,"aria-expanded","true");
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("i");
              dom.setAttribute(el5,"class","stk-icon fa fa-caret-down");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("ul");
              dom.setAttribute(el4,"class","dropdown-menu y-scroll");
              dom.setAttribute(el4,"aria-labelledby","dropdownMenu1");
              dom.setAttribute(el4,"id","dropInterval");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, content = hooks.content, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element31 = dom.childAt(fragment, [1, 1, 1]);
              var element32 = dom.childAt(element31, [3]);
              var morph0 = dom.createMorphAt(dom.childAt(element31, [1]),0,0);
              var morph1 = dom.createMorphAt(element32,1,1);
              var attrMorph0 = dom.createAttrMorph(element32, 'title');
              var morph2 = dom.createMorphAt(dom.childAt(element31, [5]),1,1);
              content(env, morph0, context, "app.lang.labels.time");
              attribute(env, attrMorph0, element32, "title", concat(env, [get(env, context, "app.lang.labels.chartInterval")]));
              content(env, morph1, context, "chartInterval.DisplayName");
              block(env, morph2, context, "each", [get(env, context, "getChartInterval")], {"keyword": "item"}, child0, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            block(env, morph0, context, "unless", [get(env, context, "compareSymbolMode")], {}, child0, null);
            block(env, morph1, context, "if", [get(env, context, "chartIntervalEnabled")], {}, child1, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","full-width font-medium");
          dom.setAttribute(el1,"id","chartToolbar");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2,"class","scroll");
          dom.setAttribute(el2,"style","list-style:none;");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3,"class","stk-chart");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"id","tabs");
          dom.setAttribute(el4,"class","stk-chart margin-right chartTabs nav nav-tabs right chart-navtabs padding-small-left margin-large-bottom margin-large-top text-align-center print-interval");
          dom.setAttribute(el4,"data-tabs","tabs ");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","intervalstyling topfixs");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row");
          dom.setAttribute(el2,"style","clear: both");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 print-interval");
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","mob-res compre-dispaly");
          dom.setAttribute(el4,"id","section2");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-md-2 col-lg-2 col-sm-4 col-xs-12 compare-symbol print-symbol");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element51 = dom.childAt(fragment, [0]);
          var element52 = dom.childAt(element51, [1, 1, 1]);
          var element53 = dom.childAt(element51, [3, 1, 1]);
          var morph0 = dom.createMorphAt(dom.childAt(element52, [1]),0,0);
          var morph1 = dom.createMorphAt(element52,3,3);
          var morph2 = dom.createMorphAt(dom.childAt(element53, [1]),1,1);
          var morph3 = dom.createMorphAt(element53,3,3);
          content(env, morph0, context, "app.lang.labels.interval");
          block(env, morph1, context, "each", [get(env, context, "periods")], {"keyword": "tabItem"}, child0, null);
          block(env, morph2, context, "if", [get(env, context, "compareSymbolEnabled")], {}, child1, null);
          block(env, morph3, context, "if", [get(env, context, "isHistory")], {}, child2, child3);
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","row");
          dom.setAttribute(el1,"style","z-index: -1; position: relative");
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col-xs-12 olhce-style");
          dom.setAttribute(el2,"id","chartItems");
          var el3 = dom.createTextNode("\n\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/img/line.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("|");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" |");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" |");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n         ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element25 = dom.childAt(fragment, [0, 1]);
          var element26 = dom.childAt(element25, [9]);
          var element27 = dom.childAt(element25, [11]);
          var element28 = dom.childAt(element25, [13]);
          var element29 = dom.childAt(element25, [15]);
          var morph0 = dom.createMorphAt(dom.childAt(element25, [1]),1,1);
          var morph1 = dom.createMorphAt(element25,7,7);
          var morph2 = dom.createMorphAt(element26,0,0);
          var morph3 = dom.createMorphAt(element26,2,2);
          var morph4 = dom.createMorphAt(element27,1,1);
          var morph5 = dom.createMorphAt(element27,3,3);
          var morph6 = dom.createMorphAt(element28,0,0);
          var morph7 = dom.createMorphAt(element28,2,2);
          var morph8 = dom.createMorphAt(element29,0,0);
          var morph9 = dom.createMorphAt(element29,2,2);
          content(env, morph0, context, "formattedDate");
          content(env, morph1, context, "stock.sDes");
          content(env, morph2, context, "app.lang.labels.open");
          content(env, morph3, context, "open");
          content(env, morph4, context, "app.lang.labels.high");
          content(env, morph5, context, "high");
          content(env, morph6, context, "app.lang.labels.low");
          content(env, morph7, context, "low");
          content(env, morph8, context, "app.lang.labels.close");
          content(env, morph9, context, "close");
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","pointsDivStyle");
            var el2 = dom.createElement("h3");
            dom.setAttribute(el2,"class","pointDivItems");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" : ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            dom.setAttribute(el2,"class","pointDivItems");
            dom.setAttribute(el2,"style","font-size: 15px;");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element23 = dom.childAt(fragment, [1]);
            var element24 = dom.childAt(element23, [0]);
            var morph0 = dom.createMorphAt(element24,0,0);
            var attrMorph0 = dom.createAttrMorph(element24, 'style');
            var morph1 = dom.createMorphAt(dom.childAt(element23, [2]),1,1);
            attribute(env, attrMorph0, element24, "style", concat(env, ["font-size: 15px; color :", get(env, context, "item.color")]));
            content(env, morph0, context, "item.name");
            content(env, morph1, context, "item.y");
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","points");
          dom.setAttribute(el1,"style","display: flex; margin-top: 15px");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          block(env, morph0, context, "each", [get(env, context, "symArray")], {"keyword": "item"}, child0, null);
          return fragment;
        }
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-check");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"class","form-check-input");
            dom.setAttribute(el2,"type","checkbox");
            dom.setAttribute(el2,"value","");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","form-check-label");
            dom.setAttribute(el2,"for","flexCheckDefault");
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [1]);
            var attrMorph0 = dom.createAttrMorph(element4, 'id');
            var morph0 = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
            attribute(env, attrMorph0, element4, "id", concat(env, [get(env, context, "item.sym")]));
            element(env, element4, context, "action", ["addSymbol", get(env, context, "item")], {"on": "change"});
            content(env, morph0, context, "item.sDes");
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-check");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"class","form-check-input");
            dom.setAttribute(el2,"type","checkbox");
            dom.setAttribute(el2,"value","");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","form-check-label");
            dom.setAttribute(el2,"for","flexCheckDefault");
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var attrMorph0 = dom.createAttrMorph(element2, 'id');
            var morph0 = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
            attribute(env, attrMorph0, element2, "id", concat(env, [get(env, context, "item.sym")]));
            element(env, element2, context, "action", ["addSymbol", get(env, context, "item")], {"on": "change"});
            content(env, morph0, context, "item.sDes");
            return fragment;
          }
        };
      }());
      var child2 = (function() {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","row");
            dom.setAttribute(el1,"style","clear:both;");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col-xs-12");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3,"id","price15minDelayLabel");
            dom.setAttribute(el3,"class","snap-note padding-small-top padding-small-bottom text-align-right");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" | ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","bold");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" | * ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("<p id=\"trailingTwelveMonthsLabel\" class=\"snap-note padding-small-top padding-small-bottom text-align-right\">* </p>");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element0 = dom.childAt(fragment, [1, 1, 1]);
            var morph0 = dom.createMorphAt(element0,1,1);
            var morph1 = dom.createMorphAt(element0,3,3);
            var morph2 = dom.createMorphAt(dom.childAt(element0, [5]),0,0);
            var morph3 = dom.createMorphAt(element0,7,7);
            content(env, morph0, context, "app.lang.labels.price15minDelay");
            content(env, morph1, context, "app.lang.labels.allPricesIn");
            content(env, morph2, context, "stock.cur");
            content(env, morph3, context, "app.lang.labels.trailingTwelveMonths");
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","fourBox");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row print-interval");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgWeekLabel");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgW");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgMonthLabel");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChgM");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChg3MLabel");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctChg3M");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-6 col-sm-3 margin-medium-bottom");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctYtdLabel");
          dom.setAttribute(el5,"class","text-align-left left full-width margin-small-bottom dir-rtl radius-style-upper");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"id","pctYtd");
          dom.setAttribute(el5,"class","text-align-right right full-width snap-font-size-3 radius-style-lower");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","clearfix");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","row margin-large-bottom print-interval");
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 btn-bottom");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-6  distsdisply");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-lg-4 col-md-4 col-sm-4 padding-small-bottom padding-small-top margin-large-bottom inline-block");
          var el6 = dom.createTextNode("\n\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6,"class","btn-primary");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-lg-4 col-md-4 col-sm-4 padding-small-bottom padding-small-top margin-large-bottom inline-block");
          var el6 = dom.createTextNode("\n\n\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6,"class","btn-primary");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","col-xs-12 col-lg-4 col-md-4 col-sm-4 padding-small-bottom padding-small-top margin-large-bottom inline-block");
          var el6 = dom.createTextNode("\n\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6,"class","btn-primary");
          dom.setAttribute(el6,"onclick","window.print()");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","fourBox");
          dom.setAttribute(el2,"class","stk-actions col-xs-12 print-interval");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col-xs-12 col-sm-12 col-md-9 col-lg-8 no-padding print-interval");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-6 col-xs-6-stk col-md-3 col-sm-3 col-lg-3 margin-medium-bottom no-padding");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","checkBoxTittle");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                           ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","form-check");
          dom.setAttribute(el5,"id","volumeCheckBox");
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("input");
          dom.setAttribute(el6,"type","checkbox");
          dom.setAttribute(el6,"value","");
          dom.setAttribute(el6,"id","flexCheckDefault");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","form-check-label");
          dom.setAttribute(el6,"for","flexCheckDefault");
          var el7 = dom.createTextNode("\n                                  ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-6 col-xs-6-stk col-md-3 col-sm-3 col-lg-3 margin-medium-bottom no-padding print-interval");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","checkBoxTittle");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                           ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","form-check");
          var el6 = dom.createTextNode("\n\n                             ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("input");
          dom.setAttribute(el6,"class","form-check-input");
          dom.setAttribute(el6,"type","checkbox");
          dom.setAttribute(el6,"value","");
          dom.setAttribute(el6,"id","CA");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                             ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","form-check-label");
          dom.setAttribute(el6,"for","flexCheckDefault");
          var el7 = dom.createTextNode("\n                                 ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                             ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                             ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                           ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","form-check");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("input");
          dom.setAttribute(el6,"class","form-check-input");
          dom.setAttribute(el6,"type","checkbox");
          dom.setAttribute(el6,"value","");
          dom.setAttribute(el6,"id","AN");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"class","form-check-label");
          dom.setAttribute(el6,"for","flexCheckDefault");
          var el7 = dom.createTextNode("\n                                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-6 col-xs-6-stk col-md-3 col-sm-3 col-lg-3 margin-medium-bottom no-padding");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","checkBoxTittle");
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                     ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col-xs-6 col-xs-6-stk col-md-3 col-sm-3 col-lg-3 margin-medium-bottom no-padding");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","checkBoxTittle");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content, inline = hooks.inline, element = hooks.element, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element5 = dom.childAt(fragment, [0]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [1, 1]);
          var element8 = dom.childAt(element6, [3, 1]);
          var element9 = dom.childAt(element6, [5, 1]);
          var element10 = dom.childAt(element6, [7, 1]);
          var element11 = dom.childAt(element5, [3, 1, 1]);
          var element12 = dom.childAt(element11, [1, 1]);
          var element13 = dom.childAt(element11, [3, 1]);
          var element14 = dom.childAt(element5, [5, 1]);
          var element15 = dom.childAt(element14, [1]);
          var element16 = dom.childAt(element14, [3]);
          var element17 = dom.childAt(element16, [3]);
          var element18 = dom.childAt(element17, [1]);
          var element19 = dom.childAt(element16, [5]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element14, [5]);
          var element22 = dom.childAt(element14, [7]);
          var attrMorph0 = dom.createAttrMorph(element7, 'class');
          var morph0 = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
          var attrMorph1 = dom.createAttrMorph(element8, 'class');
          var morph2 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
          var morph3 = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
          var attrMorph2 = dom.createAttrMorph(element9, 'class');
          var morph4 = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          var morph5 = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          var attrMorph3 = dom.createAttrMorph(element10, 'class');
          var morph6 = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
          var morph7 = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
          var morph8 = dom.createMorphAt(element12,0,0);
          var morph9 = dom.createMorphAt(element13,0,0);
          var morph10 = dom.createMorphAt(dom.childAt(element11, [5, 1]),0,0);
          var morph11 = dom.createMorphAt(dom.childAt(element15, [1, 1]),0,0);
          var morph12 = dom.createMorphAt(dom.childAt(element15, [3, 3]),1,1);
          var morph13 = dom.createMorphAt(dom.childAt(element16, [1, 1]),0,0);
          var morph14 = dom.createMorphAt(dom.childAt(element17, [3]),1,1);
          var morph15 = dom.createMorphAt(dom.childAt(element19, [3]),1,1);
          var morph16 = dom.createMorphAt(dom.childAt(element21, [1, 1]),0,0);
          var morph17 = dom.createMorphAt(element21,3,3);
          var morph18 = dom.createMorphAt(dom.childAt(element22, [1, 1]),0,0);
          var morph19 = dom.createMorphAt(element22,3,3);
          var morph20 = dom.createMorphAt(element5,7,7);
          attribute(env, attrMorph0, element7, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgW")], {})]));
          content(env, morph0, context, "app.lang.labels.pctChgWeek");
          inline(env, morph1, context, "formatNumberPercentage", [get(env, context, "stock.pctChgW")], {});
          attribute(env, attrMorph1, element8, "class", concat(env, [" radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChgM")], {})]));
          content(env, morph2, context, "app.lang.labels.pctChgMonth");
          inline(env, morph3, context, "formatNumberPercentage", [get(env, context, "stock.pctChgM")], {});
          attribute(env, attrMorph2, element9, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctChg3M")], {})]));
          content(env, morph4, context, "app.lang.labels.pctChg3Month");
          inline(env, morph5, context, "formatNumberPercentage", [get(env, context, "stock.pctChg3M")], {});
          attribute(env, attrMorph3, element10, "class", concat(env, ["radius-style full-width left ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "stock.pctYtd")], {})]));
          content(env, morph6, context, "app.lang.labels.ytd");
          inline(env, morph7, context, "formatNumberPercentage", [get(env, context, "stock.pctYtd")], {});
          element(env, element12, context, "action", ["detach"], {});
          content(env, morph8, context, "app.lang.labels.detach");
          element(env, element13, context, "action", ["downloadExcel"], {});
          content(env, morph9, context, "app.lang.labels.downloadExcel");
          content(env, morph10, context, "app.lang.labels.print");
          content(env, morph11, context, "app.lang.labels.volume");
          content(env, morph12, context, "app.lang.labels.addVol");
          content(env, morph13, context, "app.lang.labels.chartItems");
          element(env, element18, context, "action", ["enableMarker", "CA"], {"on": "change"});
          content(env, morph14, context, "app.lang.labels.corAct");
          element(env, element20, context, "action", ["enableMarker", "AN"], {"on": "change"});
          content(env, morph15, context, "app.lang.labels.announ");
          content(env, morph16, context, "app.lang.labels.indices");
          block(env, morph17, context, "each", [get(env, context, "indices")], {"keyword": "item"}, child0, null);
          content(env, morph18, context, "app.lang.labels.peers");
          block(env, morph19, context, "each", [get(env, context, "peer")], {"keyword": "item"}, child1, null);
          block(env, morph20, context, "unless", [get(env, context, "hideFootNote")], {}, child2, null);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","chartWidget");
        dom.setAttribute(el1,"class","chart-widget");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n<svg class=\"chart-gradient\" width=\"0\" height=\"0\">\n    <defs>\n        <linearGradient id=\"linear\" x2=\"0\" y2=\"100%\">\n            <stop offset=\"0%\" class=\"upper\"></stop>\n            <stop offset=\"100%\" class=\"lower\"></stop>\n        </linearGradient>\n    </defs>\n</svg>      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"id","chartContainer");
        dom.setAttribute(el2,"class","chartContainer detach dir-ltr margin-medium-bottom");
        dom.setAttribute(el2,"style","height:100%;width:100%;");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","clearfix");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        dom.setAttribute(el1,"type","text/javascript");
        var el2 = dom.createTextNode("\n    function changeClass(button) {\n        var className = button.getAttribute(\"class\");\n        if (className == \"btn btn-default border-none normal\") {\n            button.className = \"btn btn-default border-none activeVol\";\n        }\n        else {\n            button.className = \"btn btn-default border-none normal\";\n        }\n    }\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block, inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element54 = dom.childAt(fragment, [0]);
        var morph0 = dom.createMorphAt(element54,1,1);
        var morph1 = dom.createMorphAt(element54,4,4);
        var morph2 = dom.createMorphAt(element54,6,6);
        var morph3 = dom.createMorphAt(dom.childAt(element54, [8]),1,1);
        var morph4 = dom.createMorphAt(element54,10,10);
        block(env, morph0, context, "unless", [get(env, context, "includeControls")], {}, child0, null);
        block(env, morph1, context, "if", [get(env, context, "isPoints")], {}, child1, null);
        block(env, morph2, context, "unless", [get(env, context, "isPoints")], {}, child2, null);
        inline(env, morph3, context, "dynamic-high-chart", [], {"mode": get(env, context, "chartMode"), "dataArray": get(env, context, "caMarkersArr"), "announcementsMarkers": get(env, context, "ShowHideANN"), "content": get(env, context, "chartData"), "showHide": get(env, context, "ShowHide"), "markerId": get(env, context, "markerID"), "chartOptions": get(env, context, "chartOptions"), "volumeChartHeightPctg": get(env, context, "volumeChartHeightPctg"), "minValue": get(env, context, "minValue"), "maxValue": get(env, context, "maxValue"), "volumeViewEnabled": get(env, context, "volumeViewEnabled"), "chartHeight": get(env, context, "chartContainerHeight"), "chartWidth": get(env, context, "chartContainerWidth"), "indicatorList": get(env, context, "getIndicators"), "closeMode": get(env, context, "closeMode"), "dataLoadingComplete": get(env, context, "dataLoadingComplete"), "compareSymbolContent": get(env, context, "compareSymbolContent"), "chartCompareSymbolKeyList": get(env, context, "chartCompareSymbolKeyList"), "compareViewEnabled": get(env, context, "compareSymbolMode"), "compareSymbolNeedsToUpdate": get(env, context, "compareSymbolNeedsToUpdate"), "chartInterval": get(env, context, "chartInterval"), "chartCategory": get(env, context, "chartCategory"), "prevChartCategory": get(env, context, "initialChartCategory")});
        block(env, morph4, context, "unless", [get(env, context, "includeFourBoxes")], {}, child3, null);
        return fragment;
      }
    };
  }()));

});