define('ir-app/controllers/ir-widgets/factsheet-widget/snapshot-chart-widget', ['exports', 'ember', 'ir-app/controllers/base-controller', 'ir-app/models/price/price-service', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service', 'ir-app/models/price/price-business-entities/fundamentals/fundamental-constants', 'ir-app/models/price/price-business-entities/fundamentals/fundaental-common-functions', 'ir-app/models/price/price-constants', 'ir-app/components/stk-specific-components/financial-chart-snapshot'], function (exports, Ember, BaseController, priceService, utils, sharedService, FundamentalConstants, FundamentalCommonFunctions, priceConstants, FinancialChart) {

  'use strict';

  /**
   * Created by akilam on 4/7/2017.
   */

  exports['default'] = BaseController['default'].extend({
    keyListDate: undefined,

    styleObjectIS: undefined,
    dataObjectIS: undefined,
    dataKeySetIS: undefined,

    styleObjectBS: undefined,
    dataObjectBS: undefined,
    dataKeySetBS: undefined,

    styleObjectCF: undefined,
    dataObjectCF: undefined,
    dataKeySetCF: undefined,

    styleObjectR: undefined,
    dataObjectR: undefined,
    dataKeySetR: undefined,

    displayDataObject: {},
    displayStyleObject: Ember['default'].A([]),

    breakDown: FundamentalConstants['default'].BreakDown.CollapsedView,
    isExpand: 2,
    isCalendar: 1,
    isRatio: undefined,

    currentlyDataDisplayingViewType: undefined,
    currentlyDataDisplayingFilterFromDate: undefined,
    currentlyDataDisplayingFilterToDate: undefined,
    currentlyDataDisplayingIsCalendar: 1,

    fromDate: undefined,
    toDate: undefined,
    today: undefined,

    statementType: 'IS',
    filterFromDate: undefined,
    filterToDate: undefined,
    defSelectFrom: undefined,
    defSelectTo: undefined,
    defSelectView: undefined,

    reRender: false,
    isExpandButtonAvailable: true,

    titleObj: undefined,
    numberOfTabs: undefined,

    resetFieldFrom: false,
    resetFieldTo: false,
    lang: '',
    finYearEndMonth: '',
    dataDisplayFormat: {
      thousands: 1,
      millions: 2
    },

    onPrepareData: function onPrepareData() {

      var keyListView = this.get('keyListView');
      if (keyListView[keyListView.length - 1].view === 1) {
        // setting the currentViewType and viewType according to the keyListView array
        this.set('viewType', 'A');
        this.set('currentlyDataDisplayingViewType', 'A');
      } else if (keyListView[keyListView.length - 1].view === 2) {
        this.set('viewType', 'Q');
        this.set('currentlyDataDisplayingViewType', 'Q');
      } else if (keyListView[keyListView.length - 1].view === 3) {
        this.set('viewType', 'C');
        this.set('currentlyDataDisplayingViewType', 'C');
      } else if (keyListView[keyListView.length - 1].view === 4) {
        this.set('viewType', 'T');
        this.set('currentlyDataDisplayingViewType', 'T');
      }

      this.setErrorMessage();
      this.set('lang', sharedService['default'].userSettings.currentLanguage);

      var exchange = this.get('exg');
      var symbol = this.get('sym');
      var view = this.get('currentlyDataDisplayingViewType');
      this.startLoadingProgress();
      var that = this;

      var success = function success() {
        that.set('compObj', priceService['default'].companyProfileDS.getStoredObject(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage, undefined, undefined, undefined));
        that.sendDataRequest(exchange, symbol, view);
      };
      priceService['default'].companyProfileDS.getStoredObject(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, success, undefined, priceConstants['default'].DataTypes.CompanyProfileDataStore.CompanyBasicInfo);
    },

    onClearData: function onClearData() {
      priceService['default'].removeSymbolRequest(this.get('exg'), this.get('sym'));
    },

    sendDataRequest: function sendDataRequest(exchange, symbol, view) {
      var that = this;
      var postSuccess = function postSuccess() {
        that.dataDisplay(exchange, symbol, view);
        that.stopLoadingProgress();
        that.hideDataErrorMessage();
      };

      var error = function error() {
        that.showDataErrorMessage();
        that.stopLoadingProgress();
      };

      priceService['default'].sendClientFundamentalDataRequest(exchange, symbol, sharedService['default'].userSettings.currentLanguage, 'X', postSuccess, error, '5', this.get('isCalendar'));
    },

    dataDisplay: function dataDisplay(exchange, symbol, view) {
      this.set('styleObjectIS', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, 'IS', view, this.get('isCalendar')));
      this.set('dataObjectIS', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'IS', view, this.get('isCalendar')));

      this.set('styleObjectBS', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, 'BS', view, this.get('isCalendar')));
      this.set('dataObjectBS', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'BS', view, this.get('isCalendar')));

      this.set('styleObjectCF', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, 'CF', view, this.get('isCalendar')));
      this.set('dataObjectCF', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'CF', view, this.get('isCalendar')));

      this.set('styleObjectR', priceService['default'].clientFundamentalDS.getStyleObject(exchange, symbol, 'R', view, this.get('isCalendar')));
      this.set('dataObjectR', priceService['default'].clientFundamentalDS.getDataObject(exchange, symbol, 'R', view, this.get('isCalendar')));

      this.set('arrX', Ember['default'].A());
      this.set('arr1', Ember['default'].A());
      this.set('arr2', Ember['default'].A());

      this.setSnapShotData();
    },

    setSnapShotData: function setSnapShotData() {
      var chartCount = 0,
          keys = Ember['default'].A([]),
          dataObjects = Ember['default'].A([]),
          keySet = Ember['default'].A([]),
          i;
      if (this.get('dataObjectIS')) {
        this.set('displayDataObjectIS', {});
        this.filterDataValues(this.get('dataObjectIS'), this.get('displayDataObjectIS'), 'IS');

        for (i = 0; i < this.get('styleObjectIS').length; i++) {
          if (this.get('styleObjectIS')[i].chartOnFactSheet === true) {
            chartCount++;
            keys.push(this.get('styleObjectIS')[i].filedID);
            dataObjects.push(this.get('displayDataObjectIS'));
            keySet.push(this.get('dataKeySetIS'));
          }
          if (chartCount >= 2) {
            break;
          }
        }
      }

      if (this.get('dataObjectBS') && chartCount < 2) {
        this.set('displayDataObjectBS', {});
        this.filterDataValues(this.get('dataObjectBS'), this.get('displayDataObjectBS'), 'BS');

        for (i = 0; i < this.get('styleObjectBS').length; i++) {
          if (this.get('styleObjectBS')[i].chartOnFactSheet === true) {
            chartCount++;
            keys.push(this.get('styleObjectBS')[i].filedID);
            dataObjects.push(this.get('displayDataObjectBS'));
            keySet.push(this.get('dataKeySetBS'));
          }
          if (chartCount >= 2) {
            break;
          }
        }
      }

      if (this.get('dataObjectCF') && chartCount < 2) {
        this.set('displayDataObjectCF', {});
        this.filterDataValues(this.get('dataObjectCF'), this.get('displayDataObjectCF'), 'CF');

        for (i = 0; i < this.get('styleObjectCF').length; i++) {
          if (this.get('styleObjectCF')[i].chartOnFactSheet === true) {
            chartCount++;
            keys.push(this.get('styleObjectCF')[i].filedID);
            dataObjects.push(this.get('displayDataObjectCF'));
            keySet.push(this.get('dataKeySetCF'));
          }
          if (chartCount >= 2) {
            break;
          }
        }
      }

      if (this.get('dataObjectR') && chartCount < 2) {
        this.set('displayDataObjectR', {});
        this.filterDataValues(this.get('dataObjectR'), this.get('displayDataObjectR'), 'R');

        for (i = 0; i < this.get('styleObjectR').length; i++) {
          if (this.get('styleObjectR')[i].chartOnFactSheet === true) {
            chartCount++;
            keys.push(this.get('styleObjectR')[i].filedID);
            dataObjects.push(this.get('displayDataObjectR'));
            keySet.push(this.get('dataKeySetR'));
          }
          if (chartCount >= 2) {
            break;
          }
        }
      }
      this.getChartDataSet(keys.pop(), this.get('arr1'), dataObjects.pop(), keySet.pop());
      this.getChartDataSet(keys.pop(), this.get('arr2'), dataObjects.pop(), keySet.pop());

      this.set('dataSet1', this.get('arr1'));
      this.set('dataSet2', this.get('arr2'));
      this.set('xDataSetIS', this.get('arrXIS'));
    },

    getChartDataSet: function getChartDataSet(key, array, dataObj, keySet) {
      this.set('arrXIS', Ember['default'].A());
      this.set(array, Ember['default'].A());
      array.pushObject(key);
      this.get('arrXIS').pushObject('x');

      for (var i = 0; i < keySet.length; i++) {
        var element = dataObj[keySet[i]][key];
        if (this.get('isValuesInRawData')) {
          array.pushObject(element);
        } else {
          array.pushObject(element * 1000000); // client insert the values in millions, as it is processed and converted to Millions in chart component I have converted it back to raw value
        }
        this.get('arrXIS').pushObject(this.getDisplayLabel(this.get('displayDataObjectIS'), this.get('dataKeySetIS')[i], this.get('currentlyDataDisplayingViewType'), this.get('currentlyDataDisplayingIsCalendar')));
      }
    },

    getDisplayLabel: function getDisplayLabel(dataObject, key, viewType) {
      if (dataObject[key]) {
        if (viewType === 'Q') {
          return 'Q' + key.split('~')[1] + ' ' + key.split('~')[0];
        } else if (viewType === 'A') {
          return key.split('~')[0];
        }
      }
      return '';
    },

    sortKeySet: function sortKeySet(objArrayOne) {
      var keySetOne = objArrayOne.sort(function (a, b) {
        var yearOne = parseInt(a.split('~')[0]);
        var yearTwo = parseInt(b.split('~')[0]);
        var QOne = parseInt(a.split('~')[1]);
        var QTwo = parseInt(b.split('~')[1]);

        if (yearOne > yearTwo) {
          return -1;
        } else if (yearOne < yearTwo) {
          return 1;
        } else {
          if (QOne > QTwo) {
            return -1;
          } else {
            return 1;
          }
        }
      });
      this.set('dataKeySet', keySetOne);
    },

    isExpandCollapse: (function () {
      this.dataDisplay(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
    }).observes('breakDown'),

    /**
     * To get filtered data list in between from date and the to date
     * and set in to the displayDataObject.
     * @param fromDate and toDate
     */

    filterDataValues: function filterDataValues(dataObj, displayDataObj, type) {
      var key, fromDate, toDate;
      var valueObject;
      var keySet = Object.keys(dataObj).reverse();
      if (this.get('currentlyDataDisplayingViewType') === 'A') {
        toDate = keySet[keySet.length - 1];
        if (keySet.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          fromDate = keySet[0];
        } else {
          fromDate = keySet[keySet.length - parseInt(this.get('numberOFYearsDisplayed'))];
        }
      } else {
        toDate = keySet[keySet.length - 1];
        if (keySet.length < parseInt(this.get('numberOFQuartersDisplayed'))) {
          fromDate = keySet[0];
        } else {
          fromDate = keySet[keySet.length - parseInt(this.get('numberOFQuartersDisplayed'))];
        }
      }

      for (var j = 0; j < keySet.length; j++) {
        key = keySet[j];
        valueObject = dataObj[key];
        if (this._compareDate(fromDate, key, toDate)) {
          displayDataObj[key] = valueObject;
        }
      }

      switch (type) {

        case 'IS':
          this.set('dataKeySetIS', Object.keys(displayDataObj));
          this.sortKeySet(this.get('dataKeySetIS'));
          this.get('dataKeySetIS').reverse(); // to change the display format of the columns from left to right
          break;

        case 'BS':
          this.set('dataKeySetBS', Object.keys(displayDataObj));
          this.sortKeySet(this.get('dataKeySetBS'));
          this.get('dataKeySetBS').reverse();
          break;

        case 'CF':
          this.set('dataKeySetCF', Object.keys(displayDataObj));
          this.sortKeySet(this.get('dataKeySetCF'));
          this.get('dataKeySetCF').reverse();
          break;

        case 'R':
          this.set('dataKeySetR', Object.keys(displayDataObj));
          this.sortKeySet(this.get('dataKeySetR'));
          this.get('dataKeySetR').reverse();
          break;
      }

      keySet = Object.keys(displayDataObj);
      if (keySet.length <= 0) {
        return;
      }
    },

    /**
     * To get filtered style list. Iterate the filtered data list and get only the styles that having data for
     * all the fields and filter the styles according to the breakdown views.
     */

    filterStyleValue: function filterStyleValue() {
      var styleObject, i;
      var keySet = this.get('dataKeySet');
      this.set('displayStyleObject', Ember['default'].A([]));
      for (var j = 0; j < this.get('styleObject').length; j++) {
        styleObject = this.get('styleObject')[j];
        var dataObject;
        var key;
        for (i = 0; i < keySet.length; i++) {
          key = keySet[i];
          dataObject = this.get('displayDataObject')[key];
          if (dataObject[styleObject.filedID]) {
            if (this.get('breakDown') === FundamentalConstants['default'].BreakDown.CollapsedView) {
              if (styleObject.level === '1') {
                this.get('displayStyleObject').pushObject(styleObject);
              }
            } else {
              this.get('displayStyleObject').pushObject(styleObject);
            }
            break;
          }
        }
      }
      this.setTitleObj();
    },

    setTitleObj: function setTitleObj() {
      // title object contains all the styles which are sorted and sub categorized according to the group ID and group order ID
      var displayStyleObject = this.get('displayStyleObject');
      var titleObj = Ember['default'].A([]),
          temp = 0;
      var i;
      for (i = 0; i < displayStyleObject.length; i++) {
        if (parseInt(displayStyleObject[i].groupID, 10) !== temp) {
          temp++;

          titleObj.push(this._processArray(displayStyleObject, temp));
        }
      }
      this.set('titleObj', titleObj);
    },

    _processArray: function _processArray(displayStyleObject, temp) {
      var temArray;
      temArray = Ember['default'].$.grep(displayStyleObject, function (style) {
        return parseInt(style.groupID, 10) === temp;
      });
      temArray.sort(function (a, b) {
        return parseInt(a.orderInGroup, 10) - parseInt(b.orderInGroup, 10);
      });
      return temArray;
    },

    /**
     * This is a support function for filter data objects between two periods.
     * @param fromDate, date and toDate
     * @return {boolean}
     */

    _compareDate: function _compareDate(fromDate, date, toDate) {
      if (fromDate === undefined || date === undefined || toDate === undefined) {
        return false;
      }
      var splitFrom = fromDate.split('~');
      var splitDate = date.split('~');
      var splitTo = toDate.split('~');

      if (this.get('currentlyDataDisplayingViewType') === FundamentalConstants['default'].ViewTypes.VIEW_TYPE_ANNUAL) {
        if (parseInt(splitDate[0], 10) >= parseInt(fromDate, 10) && splitDate[0].localeCompare(toDate) <= 0) {
          return true;
        }
      } else {
        if (splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitFrom[0].concat(this._compareQuarter(splitFrom[1]))) >= 0 && splitDate[0].concat(this._compareQuarter(splitDate[1])).localeCompare(splitTo[0].concat(this._compareQuarter(splitTo[1]))) <= 0) {
          return true;
        }
      }
      return false;
    },

    _compareQuarter: function _compareQuarter(quarter) {
      try {
        if ('5' === quarter[quarter.length - 1]) {
          return '4';
        }
        return quarter[quarter.length - 1];
      } catch (e) {
        return quarter;
      }
    },

    _onTabChange: function _onTabChange(tabID) {

      var that = this;
      this._clearDisplay();
      this.set('isExpandButtonAvailable', false);
      this.set('isRatio', false);

      if (tabID === 1) {
        this.set('statementType', 'IS');
      } else if (tabID === 2) {
        this.set('statementType', 'BS');
      } else if (tabID === 3) {
        this.set('statementType', 'CF');
      } else if (tabID === 4) {
        this.set('statementType', 'R');
        this.set('isRatio', true);
      }

      var postSuccess = function postSuccess() {
        that._setMemoryVariables();
        that.dataDisplay(that.get('exg'), that.get('sym'), that.get('currentlyDataDisplayingViewType'));
      };

      this.dropDownSetter(postSuccess); // on tab change data will be set to currently selected criteria of the drop downs
    },

    onExpandCollapse: (function () {
      var selection = this.get('isExpand');
      if (selection === 1) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.ExpandView);
      } else if (selection === 2) {
        this.set('breakDown', FundamentalConstants['default'].BreakDown.CollapsedView);
      }
    }).observes('isExpand'),

    _clearDisplay: function _clearDisplay() {
      this.hideDataErrorMessage();
      this.set('displayDataObject', Ember['default'].A([]));
      this.set('displayStyleObject', Ember['default'].A([]));
    },

    _setMemoryVariables: function _setMemoryVariables() {
      // this method set the memory variables which are used to filter the data set
      this.set('currentlyDataDisplayingIsCalendar', this.get('isCalendar'));
      this.set('currentlyDataDisplayingViewType', this.get('viewType'));
      this.set('currentlyDataDisplayingFilterFromDate', this.get('filterFromDate'));
      this.set('currentlyDataDisplayingFilterToDate', this.get('filterToDate'));
    },

    _onClickView: function _onClickView() {
      this._clearDisplay();
      this._setMemoryVariables();
      this.startLoadingProgress();
      this.set('reRender', true);
      this.sendDataRequest(this.get('exg'), this.get('sym'), this.get('currentlyDataDisplayingViewType'));
    },

    _validator: function _validator() {
      if (this.get('filterFromDate') === undefined || this.get('filterToDate') === undefined) {
        return;
      }
      var arrayFrom = this.get('filterFromDate').split('~');
      var arrayTo = this.get('filterToDate').split('~');

      if (parseInt(arrayFrom[0]) > parseInt(arrayTo[0])) {
        if (this.get('viewDropdownListFromDate').length < parseInt(this.get('numberOFQuartersDisplayed'))) {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[0]);
        } else {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
        }
        this.set('filterFromDate', this.get('defSelectFrom').view);
        this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
        this.set('filterToDate', this.get('defSelectTo').view);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      } else if (parseInt(arrayFrom[0]) === parseInt(arrayTo[0]) && parseInt(arrayFrom[1]) > parseInt(arrayTo[1])) {
        if (this.get('viewDropdownListFromDate').length < parseInt(this.get('numberOFQuartersDisplayed'))) {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[0]);
        } else {
          this.set('defSelectFrom', this.get('viewDropdownListFromDate')[this.get('numberOFQuartersDisplayed') - 1]);
        }
        this.set('filterFromDate', this.get('defSelectFrom').view);
        this.set('defSelectTo', this.get('viewDropdownListFromDate')[0]);
        this.set('filterToDate', this.get('defSelectTo').view);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      }
    },

    _setDropdownValues: function _setDropdownValues() {
      var view = this.get('viewType');
      var that = this;
      var keyListDate,
          i,
          j,
          numberOfQuarters = 4,
          objArrayOne,
          keySetOne;
      var min_year, max_year, min_quarter, max_quarter;

      /* instead of taking min_max object from the response, we are finding the min max using the available data set , as it is more reliable */
      objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'IS', view, this.get('isCalendar'));
      if (!objArrayOne) {
        // TODO [Akila] find a better way to select the view type for the drop down
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'CF', view, this.get('isCalendar'));
      }
      if (!objArrayOne) {
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'BS', view, this.get('isCalendar'));
      }
      if (!objArrayOne) {
        objArrayOne = priceService['default'].clientFundamentalDS.getDataObject(that.get('exg'), that.get('sym'), 'R', view, this.get('isCalendar'));
      }
      if (!objArrayOne) {
        this.set('viewDropdownListFromDate', []);
        this.set('viewDropdownListToDate', []);
        this.set('defSelectFrom', undefined);
        this.set('defSelectTo', undefined);
        this.set('filterFromDate', undefined);
        this.set('filterToDate', undefined);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
        return;
      } else {
        keySetOne = Object.keys(objArrayOne);
        keySetOne = keySetOne.sort(function (a, b) {
          var yearOne = parseInt(a.split('~')[0]);
          var yearTwo = parseInt(b.split('~')[0]);
          var QOne = parseInt(a.split('~')[1]);
          var QTwo = parseInt(b.split('~')[1]);

          if (yearOne > yearTwo) {
            return -1;
          } else if (yearOne < yearTwo) {
            return 1;
          } else {
            if (QOne > QTwo) {
              return -1;
            } else {
              return 1;
            }
          }
        });

        max_year = parseInt(keySetOne[0].split('~')[0]);
        max_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[0].split('~')[1]);
        min_year = parseInt(keySetOne[keySetOne.length - 1].split('~')[0]);
        min_quarter = FundamentalCommonFunctions['default'].actualQuarter(keySetOne[keySetOne.length - 1].split('~')[1]);
      }

      if (view === 'A') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          keyListDate.push({ view: i + '~' + '5', des: i });
        }
        keyListDate.reverse(); // to invert the upper most value of the dropdown
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }
        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);
        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      } else if (view === 'Q') {
        keyListDate = [];
        for (i = min_year; i <= max_year; i++) {
          for (j = 1; j <= numberOfQuarters; j++) {
            if (i === min_year) {
              if (j < min_quarter) {
                j = min_quarter;
              }
            }
            if (i === max_year) {
              numberOfQuarters = max_quarter;
            }

            keyListDate.push({ view: i + '~' + j, des: 'Q' + j + ' ' + i });
          }
        }
        keyListDate.reverse();
        this.set('viewDropdownListFromDate', keyListDate);
        this.set('viewDropdownListToDate', keyListDate);
        if (keyListDate.length < parseInt(this.get('numberOFYearsDisplayed'))) {
          /* for some symbols data set can be less than the number
          of default entries to be displayed in the view. this if was used to handle such scenarios */
          this.set('defSelectFrom', keyListDate[keyListDate.length - 1]);
          this.set('filterFromDate', keyListDate[keyListDate.length - 1].view);
        } else {
          this.set('defSelectFrom', keyListDate[this.get('numberOFYearsDisplayed') - 1]);
          this.set('filterFromDate', keyListDate[this.get('numberOFYearsDisplayed') - 1].view);
        }

        this.set('defSelectTo', keyListDate[0]);
        this.set('filterToDate', keyListDate[0].view);

        this.set('resetFieldFrom', !this.get('resetFieldFrom'));
        this.set('resetFieldTo', !this.get('resetFieldTo'));
      }
      this.set('keyListDate', keyListDate);
    },

    finYearEnd: (function () {
      // this returns the financial ending month as a string
      this.set('finYearEndMonth', utils['default'].formatters.getMonthStrFromInt(this.get('app').lang.labels, this.get('compObj').finYearStart - 1));
    }).observes('compObj.finYearStart'),

    /*if data was loaded initially for cal=1 then there will be no data for cal=0 {calendar and fiscal}
     * thus want to load that data when the user change the period from calendar to fiscal and vice versa.
     * As response is cached, request will be locally resolved*/
    dropDownSetter: (function (postSuccess) {
      if (!this.get('exg') || !this.get('sym')) {
        return;
      }
      var that = this;

      if (that.get('currentlyDataDisplayingIsCalendar') !== that.get('isCalendar')) {
        var suc = function suc() {
          that._setDropdownValues();
          if (Ember['default'].$.isFunction(postSuccess)) {
            postSuccess();
          }
        };
        var err = function err() {
          that.showDataErrorMessage();
          that.stopLoadingProgress();
        };
        priceService['default'].sendClientFundamentalDataRequest(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage, 'X', suc, err, '5', this.get('isCalendar'));
      } else {
        that._setDropdownValues();
        if (Ember['default'].$.isFunction(postSuccess)) {
          postSuccess();
        }
      }
    }).observes('isCalendar'),

    actions: {
      onTabSelected: function onTabSelected(tabId) {
        this._onTabChange(tabId);
      },

      onSelectingView: function onSelectingView(item) {
        switch (item.view) {
          case 1:
            this.set('viewType', 'A');
            this._setDropdownValues();break;
          case 2:
            this.set('viewType', 'Q');
            this._setDropdownValues();break;
          case 3:
            this.set('viewType', 'C');
            this._setDropdownValues();break;
          case 4:
            this.set('viewType', 'T');
            this._setDropdownValues();break;
          default:
            return;
        }

        if (this.get('activateViewDropdown')) {
          this._onClickView();
        }
      },

      onSelectingFromDate: function onSelectingFromDate(item) {
        this.set('defSelectFrom', item);
        this.set('filterFromDate', item.view);
      },

      onSelectingToDate: function onSelectingToDate(item) {
        this.set('defSelectTo', item);
        this.set('filterToDate', item.view);
      },

      onClickView: function onClickView() {
        this._validator();
        this._onClickView();
      }
    },

    onAfterRender: function onAfterRender() {
      Ember['default'].$(document).ready(function () {
        Ember['default'].$('#scroll_tabs2').scrollTabs({
          scroll_distance: 200,
          // Pixel width distance for each scroll click.
          scroll_duration: 300,
          // <a href="http://www.jqueryscript.net/animation/">Animation</a> time for scrolling in milliseconds.
          left_arrow_size: 26,
          // Pixel width for the scroll button on the left side.
          right_arrow_size: 26,
          // Pixel width for the scroll button on the right side.
          click_callback: function click_callback(e) {
            // jshint ignore:line
            //Callback function on click. Accpets the click event object as an argument. The default callback function will change the page to the href in the 'rel' attribute of the item's span tag.
            var val = Ember['default'].$(this).attr('rel');
            if (val) {
              window.location.href = val;
            }
          }
        });
      });
    }
  });

  Ember['default'].Handlebars.helper('financial-chart-snapshot', FinancialChart['default']);

});