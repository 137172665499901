define('ir-app/config/extended-settings', ['exports', 'ir-app/models/chart/chart-constants'], function (exports, ChartConstants) {

    'use strict';

    exports['default'] = {
        appConfig: {
            customisation: {
                userName: 'AMILA.UNI',
                password: '123456',

                dcmsUser: '132330',
                dcmsSessionId: 'BD584E3E-DB9B-9ECF-E040-007F01005D49',
                // list of currency codes for the invest calculator
                curList: ['USD']
            }
        },

        userSettings: {
            clients: {
                alarabia: {
                    customisation: {
                        defaultTheme: 'theme-web',
                        showFooterNote: false
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        initialChartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneDay,
                        chartInterval: ChartConstants['default'].ChartViewInterval.EveryMinutes,
                        chartYAxisToRight: false,
                        volumeViewEnabled: false,
                        indicatorYAxis: {
                            labels: {
                                enabled: true,
                                x: 20
                            },
                            opposite: false
                        }
                    },

                    chartTheme: {
                        lightThemeName: 'theme-web',
                        lightThemeObj: {
                            colors: {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                gradientUpperColor: 'rgba(44, 92, 167, 0.7)',
                                areaChartBgColor: 'rgba(112,144,195, 0.7)',
                                gradientLowerColor: 'rgba(199, 212, 232, 0.7)',
                                lineColor: 'rgba(10, 41, 83, 0.8)',
                                volumeColor: '#44a96a',
                                fontColor: '#606060',
                                gridLineColor: '#ececec',
                                // candle chart
                                downColor: 'red',
                                upColor: 'green',
                                candleLineColor: 'black',
                                candleUpLineColor: 'black'
                            }
                        }
                    }
                },
                alarabiaMob: {
                    customisation: {
                        defaultTheme: 'theme-mob',
                        showFooterNote: false
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        initialChartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneDay,
                        chartInterval: ChartConstants['default'].ChartViewInterval.EveryMinutes,
                        chartYAxisToRight: false,
                        volumeViewEnabled: false,
                        indicatorYAxis: {
                            labels: {
                                enabled: true,
                                x: 20
                            },
                            opposite: false
                        }
                    },

                    chartTheme: {
                        lightThemeName: 'theme-web',
                        lightThemeObj: {
                            colors: {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                gradientUpperColor: 'rgba(44, 92, 167, 0.7)',
                                areaChartBgColor: 'rgba(112,144,195, 0.7)',
                                gradientLowerColor: 'rgba(199, 212, 232, 0.7)',
                                lineColor: 'rgba(10, 41, 83, 0.8)',
                                volumeColor: '#44a96a',
                                fontColor: '#606060',
                                gridLineColor: '#ececec',
                                // candle chart
                                downColor: 'red',
                                upColor: 'green',
                                candleLineColor: 'black',
                                candleUpLineColor: 'black'
                            }
                        }
                    }
                },
                alarabiaEn: {
                    customisation: {
                        defaultTheme: 'theme-web',
                        showFooterNote: false
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        initialChartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneDay,
                        chartInterval: ChartConstants['default'].ChartViewInterval.EveryMinutes,
                        chartYAxisToRight: false,
                        volumeViewEnabled: false,
                        indicatorYAxis: {
                            labels: {
                                enabled: true,
                                x: 20
                            },
                            opposite: false
                        }
                    },

                    chartTheme: {
                        lightThemeName: 'theme-web',
                        lightThemeObj: {
                            colors: {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                gradientUpperColor: 'rgba(44, 92, 167, 0.7)',
                                areaChartBgColor: 'rgba(112,144,195, 0.7)',
                                gradientLowerColor: 'rgba(199, 212, 232, 0.7)',
                                lineColor: 'rgba(10, 41, 83, 0.8)',
                                volumeColor: '#44a96a',
                                fontColor: '#606060',
                                gridLineColor: '#ececec',
                                // candle chart
                                downColor: 'red',
                                upColor: 'green',
                                candleLineColor: 'black',
                                candleUpLineColor: 'black'
                            }
                        }
                    }
                },

                gfh: {
                    customisation: {
                        defaultTheme: 'theme2',
                        showFooterNote: true,

                        supportedThemes: [
                            // {code: 'theme4-secondary', desc: 'Dark', category: 'Dark'},
                            // {code: 'theme4', desc: 'Light', category: 'Light'}
                        ],
                        exchangeList: [{ displayLabel: 'exgDubai', exg: 'dfm', sym: 'gfh' }, { displayLabel: 'exgKuwait', exg: 'kse', sym: 'gfh`r' }, { displayLabel: 'exgBahrain', exg: 'bse', sym: 'gfh' }]
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.History,
                        initialChartCategory: ChartConstants['default'].ChartCategory.History,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneYear.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneYear,
                        chartInterval: ChartConstants['default'].ChartViewInterval.Daily,
                        volumeViewEnabled: true
                    },

                    chartTheme: {
                        lightThemeName: 'theme2',
                        lightThemeObj: {
                            colors: {
                                gradientUpperColor: 'rgba(182, 199, 207, 0.5)', /* rgba(229, 237, 255, 0.5)*/
                                areaChartBgColor: 'rgba(182, 199, 207, 0.5)',
                                gradientLowerColor: 'rgba(182, 199, 207, 0.5)'
                            }
                        },
                        darkThemeName: 'theme1'
                    }
                },

                demo: {
                    customisation: {
                        defaultTheme: 'theme-web',
                        showFooterNote: true,

                        supportedThemes: [
                            // {code: 'theme4-secondary', desc: 'Dark', category: 'Dark'},
                            // {code: 'theme4', desc: 'Light', category: 'Light'}
                        ]
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.History,
                        initialChartCategory: ChartConstants['default'].ChartCategory.History,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneYear.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneYear,
                        chartInterval: ChartConstants['default'].ChartViewInterval.Daily,
                        volumeViewEnabled: true
                    },

                    chartTheme: {
                        lightThemeName: 'theme-web',
                        lightThemeObj: {
                            colors: {
                                gradientUpperColor: 'rgba(182, 199, 207, 0.5)', /* rgba(229, 237, 255, 0.5)*/
                                areaChartBgColor: 'rgba(182, 199, 207, 0.5)',
                                gradientLowerColor: 'rgba(182, 199, 207, 0.5)'
                            }
                        },
                        darkThemeName: 'theme1'
                    }
                },

                samba: {
                    customisation: {
                        defaultTheme: 'theme-web',
                        showFooterNote: false
                    },

                    displayFormat: {
                        dateFormat: 'DD-MM-YYYY'
                    },

                    chartDefaults: {
                        chartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        initialChartCategory: ChartConstants['default'].ChartCategory.Intraday,
                        chartDataLevel: ChartConstants['default'].ChartViewPeriod.OneDay.ChartDataLevel,
                        chartViewPeriod: ChartConstants['default'].ChartViewPeriod.OneDay,
                        chartInterval: ChartConstants['default'].ChartViewInterval.EveryMinutes,
                        chartYAxisToRight: false,
                        volumeViewEnabled: false,
                        indicatorYAxis: {
                            labels: {
                                enabled: true,
                                x: 20
                            },
                            opposite: false
                        }
                    },

                    chartTheme: {
                        lightThemeName: 'theme-web',
                        lightThemeObj: {
                            colors: {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                gradientUpperColor: 'rgba(44, 92, 167, 0.7)',
                                areaChartBgColor: 'rgba(112,144,195, 0.7)',
                                gradientLowerColor: 'rgba(199, 212, 232, 0.7)',
                                lineColor: 'rgba(10, 41, 83, 0.8)',
                                volumeColor: '#44a96a',
                                fontColor: '#606060',
                                gridLineColor: '#ececec',
                                // candle chart
                                downColor: 'red',
                                upColor: 'green',
                                candleLineColor: 'black',
                                candleUpLineColor: 'black'
                            }
                        }
                    }
                }
            }
        }
    };

});