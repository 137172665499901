define('ir-app/controllers/ir-widgets/calendar-events/financial-calendar', ['exports', 'ember', 'ir-app/models/price/price-service', 'ir-app/controllers/base-array-controller', 'ir-app/utils/utils', 'ir-app/models/shared/shared-service'], function (exports, Ember, priceService, BaseArrayController, utils, sharedService) {

    'use strict';

    exports['default'] = BaseArrayController['default'].extend({
        model: Ember['default'].A(),
        fromDate: null,
        toDate: null,
        lan: undefined,
        exchange: undefined,
        expandEventId: undefined,
        count: 0,
        pageSize: undefined,
        current: 1,
        isLoadAnnInPopup: false,
        isShowAnnPopup: false,
        isLoadingAnnPopup: false,
        annObj: null,
        toDay: '',
        showUpcoming: true,
        eventLoaded: false,
        currentYear: '',
        nextYear: '',
        janArray: [],
        febArray: [],
        marcArray: [],
        aprArray: [],
        mayArray: [],
        junArray: [],
        julArray: [],
        augArray: [],
        sepArray: [],
        octArray: [],
        novArray: [],
        decArray: [],
        categoryId: 0,
        all: true,
        cal_actions: false,
        shareholderMeeting: false,
        dividends: false,
        extraordinaryShareholder: false,
        board_of_Directors_Meeting: false,
        vacations: false,
        economy_events: false,
        trading_of_Instruments_Events: false,
        general_Company_Events: false,
        ordinary_and_Extraordinary_General_Meeting: false,
        gross_Domestic_Product: false,
        inflation: false,
        interest_Rate: false,
        oil: false,
        purchasing_Managers_Index: false,
        reports: false,
        speech: false,
        trade: false,

        subscriptionPopup: false,
        firstRequest: false,
        firstUpcomingRequest: false,
        firstPastRequest: false,
        secSortField: 0,
        showDownload: true,
        allEventUrl: '',
        eventObj: {
            start: '',
            end: '',
            title: '',
            description: '',
            location: '',
            url: '',
            status: ''
        },

        Tabs: {
            UpcomingEvents: 1,
            PastEvents: 2
        },

        // Base overrides
        onLoadWidget: function onLoadWidget() {
            //logical error
            if (this.get('showUpcoming')) {
                this.set('selectedTab', this.Tabs.UpcomingEvents);
            } else {
                this.set('selectedTab', this.Tabs.PastEvents);
            }

            this.set('toDay', new Date());
            if (this.get('isForExchange')) {
                this.set('sym', 'A');
            }

            var expandEventId = this.get('generatedArgs') ? this.get('generatedArgs')[0] : undefined;
            this.set('expandEventId', expandEventId);

            this.set('lan', sharedService['default'].userSettings.currentLanguage.toLowerCase());
            this.set('exchange', this.get('exg'));
            this.set('pageSize', this.get('pageSize'));
            this.set('isLoadAnnInPopup', this.get('loadAnnInPopup'));
            this.setErrorMessage();
            this._setDefaultDates();

            if (this.get('showCustomTitle')) {
                this.setValueToBaseSymbolContainer('showBaseTitle', true);
                this.setValueToBaseSymbolContainer('baseTitle', this.get('app').lang.labels.events);
            }
            this.set('sortAscending', true);
        },

        onPrepareData: function onPrepareData() {
            this.set('today', new Date());
            this._setBoundaryDays();
            this.set('model', priceService['default'].eventCalendarDS.getEventList(this.get('exg'), this.get('sym'), sharedService['default'].userSettings.currentLanguage));
            // Binding the scrollbar after the data is available and rendering the view
            this._setCount();
            Ember['default'].$('.nano').nanoScroller();
            var d = new Date();
            this.set('currentYear', d.getFullYear());
            this.set('nextYear', d.getFullYear() + 1);
            var that = this;
            // Set both success and failure functions to the same function as in case of a failure we at least show the symbol code instead of the symbol name
            priceService['default'].downloadParamsSymbolMasterForExchange({
                exchange: that.get('exg'),
                language: sharedService['default'].userSettings.currentLanguage,
                successFn: function successFn() {
                    that._sendRequest();
                },
                errorFn: function errorFn() {
                    that._sendRequest();
                }
            });
        },

        onCheckDataAvailability: function onCheckDataAvailability() {
            var that = this;
            var filteredCont = that.get('filteredContent');
            var eventArr = [];
            for (var i = 0; i < that.get('filteredContent').length; i++) {
                var eventObj = {
                    start: [], end: [], title: '', color: '', description: '', location: '', status: ''
                };
                var startDate = undefined;
                var endDate = undefined;
                var _status = that.get('filteredContent')[i].isPending ? 'TENTATIVE' : 'CONFIRMED';
                startDate = [that.get('filteredContent')[i].eventStartDate.slice(0, 4), that.get('filteredContent')[i].eventStartDate.slice(4, 6), that.get('filteredContent')[i].eventStartDate.slice(6, 8), 6, 30];

                if (that.get('filteredContent')[i].eventEndDate) {
                    endDate = [that.get('filteredContent')[i].eventEndDate.slice(0, 4), that.get('filteredContent')[i].eventEndDate.slice(4, 6), that.get('filteredContent')[i].eventEndDate.slice(6, 8), 6, 30];
                } else {
                    endDate = startDate;
                }
                eventObj.start = startDate;
                eventObj.end = endDate;
                eventObj.title = that.get('filteredContent')[i].category;
                eventObj.description = that.get('filteredContent')[i].eventTitle;
                eventObj.location = that.get('filteredContent')[i].location;
                eventObj.status = _status;

                //   let event =  ICS.createEvent(eventObj, (error, value) => {
                //         if (error) {
                //             console.log(error);
                //         }
                //         return value;
                //     });
                //     const url = 'data:text/calendar,' + encodeURIComponent(event);
                //     Ember.set(that.get('filteredContent')[i], 'url', url);
                //     eventArr.push(eventObj);
            }
            // let allEvents = ICS.createEvents(eventArr);
            // const urlAll = 'data:text/calendar,' + encodeURIComponent(allEvents.value);
            // that.set('allEventUrl',urlAll);
            // this._setCount();

            return that.get('filteredContent').length > 0;
        },

        _sendRequest: function _sendRequest() {
            var that = this;
            var fromDate = that.get('fromDate');
            var toDate = that.get('toDate');

            priceService['default'].eventCalendarDS.clearData(that.get('exg'), that.get('sym'), sharedService['default'].userSettings.currentLanguage);
            that.startLoadingProgress();

            priceService['default'].sendEventCalendarRequest({
                exchange: that.get('exg'),
                symbol: that.get('sym'),
                language: sharedService['default'].userSettings.currentLanguage,
                pageSize: that.get('pageSize'),
                startDate: fromDate,
                endDate: toDate,
                pageIndex: that.get('current') - 1,
                sortField: 12459,
                secSortField: this.get('secSortField'),
                categoryId: this.get('categoryId'),
                successFn: function successFn() {
                    that.onDataSuccess();
                    if (!that.get('firstRequest')) {
                        for (var i = 0; i < that.get('model').length; i++) {
                            var year = that.get('model')[i].eventStartDate.slice(0, 4);
                            var month = that.get('model')[i].eventStartDate.slice(4, 6);
                            switch (that.get('model')[i].categoryId) {
                                case "1":
                                    Ember['default'].set(that.get('model')[i], 'color', 'shareholder-meeting');
                                    break;
                                case "4":
                                    Ember['default'].set(that.get('model')[i], 'color', 'bod-meeting');
                                    break;
                                case "34":
                                    Ember['default'].set(that.get('model')[i], 'color', 'economy-events');
                                    break;
                                case "502":
                                    Ember['default'].set(that.get('model')[i], 'color', 'action');
                                    break;
                                case "503":
                                    Ember['default'].set(that.get('model')[i], 'color', 'dividends');
                                    break;
                                case "505":
                                    Ember['default'].set(that.get('model')[i], 'color', 'general-company');
                                    break;
                                default:
                                    Ember['default'].set(that.get('model')[i], 'color', 'ex-shareholder-meeting');
                                    break;
                            }
                            if (parseInt(year) === that.get('currentYear')) {
                                switch (month) {
                                    case "01":
                                        that.get('janArray').push(that.get('model')[i]);
                                        break;
                                    case "02":
                                        that.get('febArray').push(that.get('model')[i]);
                                        break;
                                    case "03":
                                        that.get('marcArray').push(that.get('model')[i]);
                                        break;
                                    case "04":
                                        that.get('aprArray').push(that.get('model')[i]);
                                        break;
                                    case "05":
                                        that.get('mayArray').push(that.get('model')[i]);
                                        break;
                                    case "06":
                                        that.get('junArray').push(that.get('model')[i]);
                                        break;
                                    case "07":
                                        that.get('julArray').push(that.get('model')[i]);
                                        break;
                                    case "08":
                                        that.get('augArray').push(that.get('model')[i]);
                                        break;
                                    case "09":
                                        that.get('sepArray').push(that.get('model')[i]);
                                        break;
                                    case "10":
                                        that.get('octArray').push(that.get('model')[i]);
                                        break;
                                    case "11":
                                        that.get('novArray').push(that.get('model')[i]);
                                        break;
                                    case "12":
                                        that.get('decArray').push(that.get('model')[i]);
                                        break;
                                }
                            }
                        }
                        that.set('firstRequest', true);
                    }
                    that.set('eventLoaded', true);
                },
                errorFn: function errorFn() {
                    that.onDataError();
                }
            });
        },

        _setCount: function _setCount() {
            if (this.get('selectedTab') === this.Tabs.UpcomingEvents) {
                this.set('count', 0);
            } else {
                this.set('count', Math.ceil(priceService['default'].eventCalendarDS.getEventCount() / this.get('pageSize')));
            }
        },

        _setBoundaryDays: function _setBoundaryDays() {
            var year = this.get('today').getFullYear();
            this.set('backDate', new Date());
            this.get('backDate').setFullYear(year - 5);
            this.set('forwardDate', new Date());
            this.get('forwardDate').setFullYear(year + 5);
        },

        _setDefaultDates: function _setDefaultDates() {
            var backdate = new Date();
            var year = backdate.getFullYear();
            backdate.setFullYear(year - 5);
            this.set('fromDate', utils['default'].formatters.convertDateToyyyymmdd(backdate));
            backdate.setFullYear(year + 5);
            this.set('toDate', utils['default'].formatters.convertDateToyyyymmdd(backdate));
        },

        _getDateTime: function _getDateTime(dateString) {
            return utils['default'].formatters.convertStringToDate(dateString);
        },

        onClearData: function onClearData() {
            this._reset();
        },

        onAfterRender: function onAfterRender() {
            // this._setActiveTab();
            Ember['default'].$('.nano').nanoScroller();
        },

        onLanguageChanged: function onLanguageChanged() {
            this._reset();
            //this.onLoadWidget();
            this.onPrepareData();
        },

        _reset: function _reset() {
            this.set('fromDate', null);
            this.set('toDate', null);
            this.set('model', Ember['default'].A());
            this.set('current', 1);
        },

        setNoEvents: function setNoEvents() {
            this.set('errorMessage', this.get('app').lang.messages.dataNotAvailable);
        },

        setErrorMessage: (function () {
            if (this.get('filteredContent').length > 0) {
                this.hideDataErrorMessage();
            } else if (!this.get('isLoading')) {
                this.showDataErrorMessage();
                this.set('errorMessage', this.get('app').lang.messages.noEvents);
            }
        }).observes('filteredContent'),

        filteredContent: (function () {
            var that = this;
            return this.get('model').filter((function (that) {
                return function (theObject) {
                    if (that.get('selectedTab') === that.Tabs.UpcomingEvents) {
                        that.set('showDownload', true);
                        return that._getDateTime(theObject.eventStartDate) >= that.get('toDay');
                    } else {
                        that.set('showDownload', false);
                        return that._getDateTime(theObject.eventStartDate) < that.get('toDay');
                    }
                };
            })(that));
        }).property('selectedTab', 'categoryId', 'model.@each'),

        sendAnnouncementBodyRequest: function sendAnnouncementBodyRequest(annId) {
            var that = this;
            that.set('isShowAnnPopup', true);
            var annObj = priceService['default'].announcementDS.getAnnouncement(annId);
            this.set('annObj', annObj);

            if (!annObj.get('isBodyUpdated')) {
                that.set('isLoadingAnnPopup', true);
                // that.annObj.set('bod', that.get('app').lang.messages.loading);

                priceService['default'].sendNewsAnnBodyRequest({
                    id: annObj.get('id'),
                    type: annObj.get('type'),
                    reqSuccessFn: function reqSuccessFn() {
                        that.set('isLoadingAnnPopup', false);
                    },
                    reqFailureFn: function reqFailureFn() {
                        that.annObj.set('isBodyUpdated', false);
                        that.set('isShowAnnPopup', false);
                    },
                    lan: sharedService['default'].userSettings.currentLanguage
                });
            }
        },

        closeAnnPopup: function closeAnnPopup() {
            var that = this;
            that.set('isShowAnnPopup', false);
            this.set('annObj', null);
        },

        _onTabChange: function _onTabChange(tabId) {
            this.set('selectedTab', tabId);
            this._setCount();
        },
        upComingEventTabSelected: (function () {
            return this.get('selectedTab') === this.Tabs.UpcomingEvents;
        }).property('selectedTab'),
        actions: {

            onTabSelected: function onTabSelected(tabId) {
                this._onTabChange(tabId);
            },

            filterData: function filterData(categoryId) {
                this.set('current', 1);
                this.set('categoryId', categoryId);
                if (categoryId === 0) {
                    this.set('secSortField', 0);
                } else {
                    this.set('secSortField', 12457);
                }
                this.startLoadingProgress();
                this._sendRequest();
            },
            subscribeEvents: function subscribeEvents() {
                this.set('message', '');
                // document.getElementsByName('triggerFreq')[0].checked = true;
                this.set('subscriptionPopup', true);
            },
            closeSubPopup: function closeSubPopup() {
                var that = this;
                Ember['default'].$('#eventForm')[0].reset();
                that.set('lName', '');
                that.set('company', '');
                that.set('profession', '');
                that.set('fName', '');
                that.set('email', '');
                that.set('all', true);
                this.set('subscriptionPopup', false);
            },

            searchByDateSelection: function searchByDateSelection() {
                var fromDate = this.get('fromDate');
                var toDate = this.get('toDate');

                if (typeof fromDate === 'object' && typeof toDate === 'object') {
                    this.set('fromDate', utils['default'].formatters.convertDateToyyyymmdd(fromDate));
                    this.set('toDate', utils['default'].formatters.convertDateToyyyymmdd(toDate));
                    this.setNoEvents();
                    // this.setErrorMessage();
                    this.set('current', 1);
                    this.startLoadingProgress();
                    this._sendRequest();
                } else {
                    this.set('errorMessage', this.get('app').lang.messages.selectFromToDates);
                    this.set('showError', true);
                }
                this.set('fromDate', fromDate);
                this.set('toDate', toDate);
            },

            resetDateSelection: function resetDateSelection() {
                this.set('expandEventId', undefined);
                this.onLanguageChanged();
            },

            pageChanged: function pageChanged(current) {
                this.startLoadingProgress();
                this.set('current', current);
                this._sendRequest();
            },

            loadAnnPopup: function loadAnnPopup(annId) {
                this.sendAnnouncementBodyRequest(annId);
            },

            closeAnnPopup: function closeAnnPopup() {
                this.closeAnnPopup();
            },
            clearForm: function clearForm() {
                Ember['default'].$('#eventForm')[0].reset();
            },

            formSubmit: function formSubmit() {
                var that = this;
                var exchange = this.get('exg');
                var symbol = this.get('sym');
                var triggered = false;
                var lastName = this.get('lName');
                var company = this.get('company');
                var profession = this.get('profession');
                var firstName = this.get('fName');
                var email = this.get('email');
                var triggerFrequencyRadio = document.getElementsByName('triggerFreq');
                var triggerFrequency = '1';
                var criteriaArr = [];
                for (var i = 0; i < triggerFrequencyRadio.length; i++) {
                    if (triggerFrequencyRadio[i].checked === true) {
                        triggerFrequency = triggerFrequencyRadio[i].value;
                        triggered = true;
                        break;
                    }
                }
                var concatArr = [];
                var criteria = undefined;
                if (this.get('cal_actions')) {
                    concatArr.push(triggerFrequency + '' + 'Actions');
                }
                if (this.get('all')) {
                    concatArr.push(triggerFrequency + '' + '*');
                }
                if (this.get('shareholderMeeting')) {
                    concatArr.push(triggerFrequency + '' + 'Shareholder_Meeting');
                }
                if (this.get('dividends')) {
                    concatArr.push(triggerFrequency + '' + 'Dividends');
                }
                if (this.get('extraordinaryShareholder')) {
                    concatArr.push(triggerFrequency + '' + 'Extraordinary_Shareholder_Meeting');
                }
                if (this.get('board_of_Directors_Meeting')) {
                    concatArr.push(triggerFrequency + '' + 'Board_of_Directors_Meeting');
                }
                if (this.get('vacations')) {
                    concatArr.push(triggerFrequency + '' + 'Vacations');
                }
                if (this.get('economy_events')) {
                    concatArr.push(triggerFrequency + '' + 'Economy_Events');
                }
                if (this.get('trading_of_Instruments_Events')) {
                    concatArr.push(triggerFrequency + '' + 'Trading_of_Instruments_Events');
                }
                if (this.get('general_Company_Events')) {
                    concatArr.push(triggerFrequency + '' + 'General_Company_Events');
                }
                if (this.get('ordinary_and_Extraordinary_General_Meeting')) {
                    concatArr.push(triggerFrequency + '' + 'Ordinary_and_Extraordinary_General_Meeting');
                }
                if (this.get('gross_Domestic_Product')) {
                    concatArr.push(triggerFrequency + '' + 'Gross_Domestic_Product_(GDP)');
                }
                if (this.get('inflation')) {
                    concatArr.push(triggerFrequency + '' + 'Inflation');
                }
                if (this.get('interest_Rate')) {
                    concatArr.push(triggerFrequency + '' + 'Interest_Rate');
                }
                if (this.get('oil')) {
                    concatArr.push(triggerFrequency + '' + 'Oil');
                }
                if (this.get('purchasing_Managers_Index')) {
                    concatArr.push(triggerFrequency + '' + 'Purchasing Managers\'_Index_(PMI)');
                }
                if (this.get('reports')) {
                    concatArr.push(triggerFrequency + '' + 'Reports');
                }
                if (this.get('speech')) {
                    concatArr.push(triggerFrequency + '' + 'Speech');
                }
                if (this.get('trade')) {
                    concatArr.push(triggerFrequency + '' + 'Trade');
                }
                for (var i = 0; i < concatArr.length; i++) {
                    criteriaArr.push(['CE', 13, concatArr[i], 'D', 0, 'X'].join(utils['default'].Constants.StringConst.Tilde));
                }
                var criteriaString = criteriaArr.join();
                if (!lastName || !firstName || !email || !profession || !company) {
                    this.set('message', 'please fill all fields!');
                } else {
                    if (!triggered) {
                        this.set('message', 'Please select a reminder date!');
                    } else {
                        if (this._validateEmail(email)) {
                            this.set('message', '');
                            var onSuccess = function onSuccess(result) {
                                that.set('message', 'Alert request sent successfully. Please check your email for verification');
                                Ember['default'].$('#eventForm')[0].reset();
                                that.set('lName', '');
                                that.set('company', '');
                                that.set('profession', '');
                                that.set('fName', '');
                                that.set('email', '');
                                that.set('all', true);
                                console.log(result);
                            };

                            var onError = function onError(error) {
                                that.set('message', 'Error in Alert Request. Please try again.!');
                                console.log(error);
                            };
                            that.set('message', '');
                            priceService['default'].sendCalEventSubscription(exchange, symbol, criteriaString, firstName, lastName, email, company, profession, onSuccess, onError);
                        }
                    }
                }
            }
        },
        _validateEmail: function _validateEmail(mailAdr) {
            var validMail = mailAdr ? mailAdr.trim() : '';
            var eMail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (eMail.test(validMail)) {
                this.set('ErrorFromEmail', '');
                return true;
            } else {
                this.set('ErrorFromEmail', this.get('app').lang.messages.invalidEmail);
                return false;
            }
        }
    });

});