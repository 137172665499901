define('ir-app/templates/ir-mobile/calculator-widgets/investment-calculator', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","alert alert-warning padding-small font-large margin-medium-top margin-medium-bottom");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element24 = dom.childAt(fragment, [1]);
          var morph0 = dom.createMorphAt(element24,0,0);
          var morph1 = dom.createMorphAt(element24,2,2);
          content(env, morph0, context, "app.lang.labels.noTradeDataMessage");
          inline(env, morph1, context, "formatToDisplayDate", [get(env, context, "startDateStats.DT")], {});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","alert alert-warning padding-small font-large margin-medium-top margin-medium-bottom");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element23 = dom.childAt(fragment, [1]);
          var morph0 = dom.createMorphAt(element23,0,0);
          var morph1 = dom.createMorphAt(element23,2,2);
          content(env, morph0, context, "app.lang.labels.noTradeDataMessage");
          inline(env, morph1, context, "formatToDisplayDate", [get(env, context, "endDateStats.DT")], {});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","alert alert-warning padding-small font-large margin-medium-top margin-medium-bottom");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          content(env, morph0, context, "app.lang.labels.invalidInputs");
          return fragment;
        }
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","row");
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("<div class=\"horizontal-sep-line margin-medium-bottom\"></div>");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("<div class=\"horizontal-sep-line margin-medium-bottom\"></div>");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("<div class=\"horizontal-sep-line margin-medium-bottom\"></div>");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("<div class=\"horizontal-sep-line margin-medium-bottom\"></div>");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","row calculator");
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 col-xsm-4 col-sm-4 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-right right full-width snap-font-size-4 radius-style-lower");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","clearfix");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 col-xsm-4 col-sm-4 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-right right full-width snap-font-size-4 radius-style-lower");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","clearfix");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 col-xsm-4 col-sm-4 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-right right full-width snap-font-size-4 radius-style-lower");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                                ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","clearfix");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","horizontal-sep-line margin-medium-bottom");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element11 = dom.childAt(fragment, [1]);
                var element12 = dom.childAt(element11, [1]);
                var element13 = dom.childAt(element11, [3]);
                var element14 = dom.childAt(element11, [5]);
                var element15 = dom.childAt(element11, [7]);
                var element16 = dom.childAt(element11, [9]);
                var element17 = dom.childAt(element11, [11]);
                var element18 = dom.childAt(fragment, [3]);
                var element19 = dom.childAt(element18, [1, 1]);
                var element20 = dom.childAt(element18, [3, 1]);
                var element21 = dom.childAt(element18, [5, 1]);
                var morph0 = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
                var morph1 = dom.createMorphAt(dom.childAt(element12, [3]),0,0);
                var morph2 = dom.createMorphAt(dom.childAt(element13, [3]),0,0);
                var morph3 = dom.createMorphAt(dom.childAt(element13, [5]),0,0);
                var morph4 = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
                var morph5 = dom.createMorphAt(dom.childAt(element14, [3]),0,0);
                var morph6 = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
                var morph7 = dom.createMorphAt(dom.childAt(element15, [5]),0,0);
                var morph8 = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
                var morph9 = dom.createMorphAt(dom.childAt(element16, [5]),0,0);
                var morph10 = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
                var morph11 = dom.createMorphAt(dom.childAt(element17, [5]),0,0);
                var attrMorph0 = dom.createAttrMorph(element19, 'class');
                var morph12 = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
                var morph13 = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
                var attrMorph1 = dom.createAttrMorph(element20, 'class');
                var morph14 = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                var morph15 = dom.createMorphAt(dom.childAt(element20, [3]),0,0);
                var attrMorph2 = dom.createAttrMorph(element21, 'class');
                var morph16 = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                var morph17 = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
                content(env, morph0, context, "app.lang.labels.investDate");
                inline(env, morph1, context, "formatToDisplayDate", [get(env, context, "startDateStats.DT")], {});
                content(env, morph2, context, "app.lang.labels.stkPrice");
                inline(env, morph3, context, "formatNumber", [get(env, context, "startPrice"), get(env, context, "stock.filteredDeci")], {});
                content(env, morph4, context, "app.lang.labels.sharesPurchased");
                content(env, morph5, context, "shares");
                content(env, morph6, context, "app.lang.labels.endDate");
                inline(env, morph7, context, "formatToDisplayDate", [get(env, context, "endDateStats.DT")], {});
                content(env, morph8, context, "app.lang.labels.stkPrice");
                inline(env, morph9, context, "formatNumber", [get(env, context, "endPrice"), get(env, context, "stock.filteredDeci")], {});
                content(env, morph10, context, "app.lang.labels.totDivid");
                content(env, morph11, context, "totDividends");
                attribute(env, attrMorph0, element19, "class", concat(env, ["radius-style padding-medium ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "totReturn")], {})]));
                content(env, morph12, context, "app.lang.labels.totReturn");
                content(env, morph13, context, "totReturn");
                attribute(env, attrMorph1, element20, "class", concat(env, ["radius-style padding-medium ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "rtnPercentage")], {})]));
                content(env, morph14, context, "app.lang.labels.rtnPercentage");
                inline(env, morph15, context, "formatNumberPercentage", [get(env, context, "rtnPercentage")], {});
                attribute(env, attrMorph2, element21, "class", concat(env, ["radius-style padding-medium ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "annualReturn")], {})]));
                content(env, morph16, context, "app.lang.labels.annualReturn");
                inline(env, morph17, context, "formatNumberPercentage", [get(env, context, "annualReturn")], {});
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","slide-panel-content cal-results");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              block(env, morph0, context, "if", [get(env, context, "isCalculated")], {}, child0, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("script");
            var el2 = dom.createTextNode("\n                // close the lateral panel\n                $('.slide-panel').on('click', function (event) {\n                    if ($(event.target).is('.slide-panel') || $(event.target).is('.slide-panel-close')) {\n                        $('.slide-panel').removeClass('is-visible');\n                        event.preventDefault();\n                    }\n                });\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","slide-panel from-right");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","slide-panel-container");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col-xs-12 full-height");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","row");
            var el5 = dom.createTextNode("\n                            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","slide-panel-header col-xs-12");
            var el6 = dom.createTextNode("\n                                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("div");
            dom.setAttribute(el6,"class","row");
            var el7 = dom.createTextNode("\n                                    ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("table");
            dom.setAttribute(el7,"width","100%");
            dom.setAttribute(el7,"cellspacing","0");
            dom.setAttribute(el7,"cellpadding","0");
            dom.setAttribute(el7,"border","0");
            var el8 = dom.createTextNode("\n                                        ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("tr");
            var el9 = dom.createTextNode("\n                                            ");
            dom.appendChild(el8, el9);
            var el9 = dom.createElement("td");
            var el10 = dom.createTextNode("\n                                                ");
            dom.appendChild(el9, el10);
            var el10 = dom.createElement("h1");
            dom.setAttribute(el10,"class","col-xs-10");
            var el11 = dom.createComment("");
            dom.appendChild(el10, el11);
            dom.appendChild(el9, el10);
            var el10 = dom.createTextNode("\n                                            ");
            dom.appendChild(el9, el10);
            dom.appendChild(el8, el9);
            var el9 = dom.createTextNode("\n                                            ");
            dom.appendChild(el8, el9);
            var el9 = dom.createElement("td");
            dom.setAttribute(el9,"class","close-btn-container");
            var el10 = dom.createTextNode("\n                                                ");
            dom.appendChild(el9, el10);
            var el10 = dom.createElement("a");
            dom.setAttribute(el10,"href","#0");
            dom.setAttribute(el10,"class","col-xs-2 slide-panel-close");
            dom.appendChild(el9, el10);
            var el10 = dom.createTextNode("\n                                            ");
            dom.appendChild(el9, el10);
            dom.appendChild(el8, el9);
            var el9 = dom.createTextNode("\n                                        ");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                                    ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                                ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment(" slide-panel-container ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" slide-panel ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element22 = dom.childAt(fragment, [3, 1, 1]);
            var morph0 = dom.createMorphAt(dom.childAt(element22, [1, 1, 1, 1, 1, 1, 1]),0,0);
            var morph1 = dom.createMorphAt(element22,3,3);
            var morph2 = dom.createMorphAt(element22,5,5);
            content(env, morph0, context, "app.lang.labels.results");
            inline(env, morph1, context, "loading-indicator", [], {"isLoading": get(env, context, "isLoading")});
            block(env, morph2, context, "if", [get(env, context, "dataAvailable")], {}, child0, null);
            return fragment;
          }
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.1",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","row");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-6 col-xsm-4 col-md-4 col-lg-2 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","snap-font-color-2 full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3,"class","bold full-width");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","row calculator");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 col-xsm-4 col-sm-4 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-right right full-width snap-font-size-4 radius-style-lower");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","clearfix");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 col-xsm-4 col-sm-4 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-right right full-width snap-font-size-4 radius-style-lower");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","clearfix");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col-xs-12 col-xsm-4 col-sm-4 margin-medium-bottom");
                var el3 = dom.createTextNode("\n                                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-left left full-width margin-small-bottom radius-style-upper");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text-align-right right full-width snap-font-size-4 radius-style-lower");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                    ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","clearfix");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1]);
                var element2 = dom.childAt(element0, [3]);
                var element3 = dom.childAt(element0, [5]);
                var element4 = dom.childAt(element0, [7]);
                var element5 = dom.childAt(element0, [9]);
                var element6 = dom.childAt(element0, [11]);
                var element7 = dom.childAt(fragment, [3]);
                var element8 = dom.childAt(element7, [1, 1]);
                var element9 = dom.childAt(element7, [3, 1]);
                var element10 = dom.childAt(element7, [5, 1]);
                var morph0 = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
                var morph1 = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
                var morph2 = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
                var morph3 = dom.createMorphAt(dom.childAt(element2, [3]),0,0);
                var morph4 = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                var morph5 = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
                var morph6 = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
                var morph7 = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
                var morph8 = dom.createMorphAt(dom.childAt(element5, [1]),0,0);
                var morph9 = dom.createMorphAt(dom.childAt(element5, [3]),0,0);
                var morph10 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
                var morph11 = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
                var attrMorph0 = dom.createAttrMorph(element8, 'class');
                var morph12 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
                var morph13 = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
                var attrMorph1 = dom.createAttrMorph(element9, 'class');
                var morph14 = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
                var morph15 = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
                var attrMorph2 = dom.createAttrMorph(element10, 'class');
                var morph16 = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                var morph17 = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
                content(env, morph0, context, "app.lang.labels.investDate");
                inline(env, morph1, context, "formatToDisplayDate", [get(env, context, "startDateStats.DT")], {});
                content(env, morph2, context, "app.lang.labels.stkPrice");
                inline(env, morph3, context, "formatNumber", [get(env, context, "startPrice"), get(env, context, "stock.filteredDeci")], {});
                content(env, morph4, context, "app.lang.labels.sharesPurchased");
                content(env, morph5, context, "shares");
                content(env, morph6, context, "app.lang.labels.endDate");
                inline(env, morph7, context, "formatToDisplayDate", [get(env, context, "endDateStats.DT")], {});
                content(env, morph8, context, "app.lang.labels.stkPrice");
                inline(env, morph9, context, "formatNumber", [get(env, context, "endPrice"), get(env, context, "stock.filteredDeci")], {});
                content(env, morph10, context, "app.lang.labels.totDivid");
                content(env, morph11, context, "totDividends");
                attribute(env, attrMorph0, element8, "class", concat(env, ["radius-style padding-medium ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "totReturn")], {})]));
                content(env, morph12, context, "app.lang.labels.totReturn");
                content(env, morph13, context, "totReturn");
                attribute(env, attrMorph1, element9, "class", concat(env, ["radius-style padding-medium ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "rtnPercentage")], {})]));
                content(env, morph14, context, "app.lang.labels.rtnPercentage");
                inline(env, morph15, context, "formatNumberPercentage", [get(env, context, "rtnPercentage")], {});
                attribute(env, attrMorph2, element10, "class", concat(env, ["radius-style padding-medium ", subexpr(env, context, "getPositiveNegativeStyle", [get(env, context, "annualReturn")], {})]));
                content(env, morph16, context, "app.lang.labels.annualReturn");
                inline(env, morph17, context, "formatNumberPercentage", [get(env, context, "annualReturn")], {});
                return fragment;
              }
            };
          }());
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.1",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","slide-panel-content cal-results");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks, get = hooks.get, block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              block(env, morph0, context, "if", [get(env, context, "isCalculated")], {}, child0, null);
              return fragment;
            }
          };
        }());
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.1",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks, get = hooks.get, inline = hooks.inline, block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
            var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            inline(env, morph0, context, "loading-indicator", [], {"isLoading": get(env, context, "isLoading")});
            block(env, morph1, context, "if", [get(env, context, "dataAvailable")], {}, child0, null);
            return fragment;
          }
        };
      }());
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "if", [get(env, context, "showInSlider")], {}, child0, child1);
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    jQuery(document).ready(function ($) {\n        //open the lateral panel\n        $('.slide-panel-btn').on('click', function (event) {\n            event.preventDefault();\n            $('.slide-panel').addClass('is-visible');\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","investmentCal");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","row calc");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","inline-block col-xs-12 col-sm-12 col-md-12 margin-large-bottom");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 col-xsm-6 col-sm-6 col-md-6 col-lg-6 margin-large-bottom left-field");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-2 bold margin-medium-bottom text-align-1");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","date-picker");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-group has-feedback");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-calendar form-control-feedback");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12 col-xsm-6 col-sm-6 col-md-6 col-lg-6 margin-large-bottom right-fields");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","snap-font-size-1 snap-font-color-2 bold margin-medium-bottom text-align-1 label");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","date-picker");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","display-inline full-width form-group has-feedback");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","fa fa-calendar form-control-feedback");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("search-query form-control");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","margin-large-bottom inline-block full-width input-width-adjust");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 col-pad-right col-xsm-5 col-sm-6 col-md-6 bold text-align-1 z-index-up");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","radio-inline bold margin-medium-bottom");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("(");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(")\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","p-relative");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","snap-font-size-2 snap-font-color-2 text-align-center text-between-input");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 col-pad-left col-xsm-5 col-sm-6 col-md-6 right-fields force-right");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","radio-inline bold margin-medium-bottom label radio-padding");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 col-xsm-6 col-sm-3 col-md-3 text-align-left margin-large-bottom");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-size-1 snap-font-color-2 text-align-left");
        var el5 = dom.createTextNode("\n                 \n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("<select class=\"dropdown select-style-1\">");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("<option>AED</option>");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("<option>SAR</option>");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("</select>");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("{!{view \"select\" content=currencyList value=selectedCur classNames='dropdown select-style-1'}}");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"id","financial_dropdown_view");
        dom.setAttribute(el5,"class","snap-font-size-1 snap-font-color-1 margin-large-bottom select currency-dropdown");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-6 col-xsm-3 col-sm-3 col-md-3 text-align-left margin-medium-top no-left-padding");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","snap-font-size-1 snap-font-color-2 text-align-left");
        var el5 = dom.createTextNode("\n                 \n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","checkbox-inline margin-medium-bottom");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","single-line-text");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","more slide-panel-btn");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 col-sm-6 col-xsm-3 col-md-6 col-lg-6 margin-medium-bottom");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","event-button cal-btn margin-top-large");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6,"class","add-calc border-none center-block padding-medium-top padding-large-right padding-medium-bottom padding-large-left");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, block = hooks.block, element = hooks.element;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element25 = dom.childAt(fragment, [2]);
        var element26 = dom.childAt(element25, [1]);
        var element27 = dom.childAt(element26, [1, 1]);
        var element28 = dom.childAt(element27, [1]);
        var element29 = dom.childAt(element27, [3]);
        var element30 = dom.childAt(element26, [3]);
        var element31 = dom.childAt(element30, [1]);
        var element32 = dom.childAt(element31, [1]);
        var element33 = dom.childAt(element30, [5]);
        var element34 = dom.childAt(element33, [1]);
        var element35 = dom.childAt(element26, [7, 3]);
        var element36 = dom.childAt(element26, [9]);
        var morph0 = dom.createMorphAt(dom.childAt(element28, [1]),1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element28, [3, 1]),1,1);
        var morph2 = dom.createMorphAt(element28,5,5);
        var morph3 = dom.createMorphAt(dom.childAt(element29, [1]),1,1);
        var morph4 = dom.createMorphAt(dom.childAt(element29, [3, 1]),1,1);
        var morph5 = dom.createMorphAt(element29,7,7);
        var morph6 = dom.createMorphAt(element32,1,1);
        var morph7 = dom.createMorphAt(element32,3,3);
        var morph8 = dom.createMorphAt(element32,5,5);
        var morph9 = dom.createMorphAt(dom.childAt(element31, [3]),1,1);
        var morph10 = dom.createMorphAt(dom.childAt(element30, [3, 1]),1,1);
        var morph11 = dom.createMorphAt(element34,1,1);
        var morph12 = dom.createMorphAt(element34,3,3);
        var morph13 = dom.createMorphAt(dom.childAt(element33, [3]),1,1);
        var morph14 = dom.createMorphAt(dom.childAt(element26, [5, 3, 11]),1,1);
        var morph15 = dom.createMorphAt(element35,1,1);
        var morph16 = dom.createMorphAt(dom.childAt(element35, [2]),0,0);
        var morph17 = dom.createMorphAt(dom.childAt(element36, [1, 1, 1, 1]),0,0);
        var morph18 = dom.createMorphAt(element25,3,3);
        var morph19 = dom.createMorphAt(element25,5,5);
        content(env, morph0, context, "app.lang.labels.investDate");
        inline(env, morph1, context, "bootstrap-datepicker", [], {"readonly": "readonly", "language": get(env, context, "lan"), "placeholder": get(env, context, "app.lang.labels.selectDate"), "class": "form-control date-picker", "value": get(env, context, "fromDate"), "startDate": get(env, context, "oldestDate"), "endDate": get(env, context, "today"), "autoclose": true, "todayHighlight": true});
        block(env, morph2, context, "if", [get(env, context, "notTradedOnStartDate")], {}, child0, null);
        content(env, morph3, context, "app.lang.labels.endDate");
        inline(env, morph4, context, "bootstrap-datepicker", [], {"readonly": "readonly", "language": get(env, context, "lan"), "placeholder": get(env, context, "app.lang.labels.selectDate"), "class": "form-control date-picker", "value": get(env, context, "toDate"), "autoclose": true, "startDate": get(env, context, "fromDate"), "endDate": get(env, context, "today"), "todayHighlight": true});
        block(env, morph5, context, "if", [get(env, context, "notTradedOnEndDate")], {}, child1, null);
        inline(env, morph6, context, "radio-button", [], {"value": 1, "checked": get(env, context, "calType")});
        content(env, morph7, context, "app.lang.labels.amountInvest");
        content(env, morph8, context, "selectedCur.val");
        inline(env, morph9, context, "input", [], {"type": "number", "value": get(env, context, "amount"), "disabled": get(env, context, "isSharesEnabled"), "class": "input-text-style-1"});
        content(env, morph10, context, "app.lang.labels.or");
        inline(env, morph11, context, "radio-button", [], {"value": 2, "checked": get(env, context, "calType")});
        content(env, morph12, context, "app.lang.labels.numSharesPurchased");
        inline(env, morph13, context, "input", [], {"type": "number", "value": get(env, context, "shares"), "disabled": get(env, context, "isAmountEnabled"), "class": "input-text-style-1"});
        inline(env, morph14, context, "bootstrap-dropdown-select", [], {"options": get(env, context, "dropdownCurList"), "valueKey": "view", "defaultSelect": get(env, context, "selectedCur"), "labelKey": "view", "buttonClass": "btn-special-widget-header", "selectAction": "onChangeCurrency", "app": get(env, context, "app")});
        inline(env, morph15, context, "input", [], {"type": "checkbox", "checked": get(env, context, "isReInvst")});
        content(env, morph16, context, "app.lang.labels.reInvstDivid");
        element(env, element36, context, "action", ["calculateOnClick"], {});
        content(env, morph17, context, "app.lang.labels.calc");
        block(env, morph18, context, "if", [get(env, context, "invalidInputs")], {}, child2, null);
        block(env, morph19, context, "if", [get(env, context, "validInputs")], {}, child3, null);
        return fragment;
      }
    };
  }()));

});