define('ir-app/config/price-settings', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        // LDC
        connectionParameters: {
            primary: {
                // ip: '78.136.43.187',    // LDC prod
                // port: '19006',
                // ip: 'mubasher.alarabiya.net/ws',    // LDC prod
                ip: 'ir.directfn.com/ws', // LDC prod [GFH]
                port: '',
                secure: true // Is connection secure - true or false
            },
            secondary: {
                ip: '31.222.133.25', // LDC Uat
                port: '9018',
                secure: false // Is connection secure - true or false
            }
        }

        // UAT - Saudi Awal-net
        /** connectionParameters: {
            primary: {
                ip: 'data-sa9.mubasher.net',
                port: '9018',
                secure: false // Is connection secure - true or false
            },
            secondary: {
                ip: 'data-sa9.mubasher.net',
                port: '9018',
                secure: false // Is connection secure - true or false
            }
        },*/

        // Local
        /** connectionParameters: {
            primary: {
                ip: '192.168.13.84',
                port: '8090',
                secure: false // Is connection secure - true or false
            },
            secondary: {
                ip: '192.168.13.84',
                port: '8090',
                secure: false // Is connection secure - true or false
            }
        },*/
    };

});