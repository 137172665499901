define('ir-app/templates/ir-mobile/quote-widgets/stock-price-ticker', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","item");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","margin-small-left margin-small-right text-white bold ");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","value no-margin");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item value no-margin");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item value no-margin");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item value no-margin");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [3]);
          var element7 = dom.childAt(element5, [5]);
          var element8 = dom.childAt(element5, [7]);
          var element9 = dom.childAt(element5, [9]);
          var morph0 = dom.createMorphAt(dom.childAt(element5, [1]),1,1);
          var attrMorph0 = dom.createAttrMorph(element6, 'class');
          var morph1 = dom.createMorphAt(dom.childAt(element6, [1]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
          var morph3 = dom.createMorphAt(element7,0,0);
          var morph4 = dom.createMorphAt(element7,2,2);
          var morph5 = dom.createMorphAt(element8,0,0);
          var morph6 = dom.createMorphAt(element8,2,2);
          var morph7 = dom.createMorphAt(element9,0,0);
          var morph8 = dom.createMorphAt(element9,2,2);
          var morph9 = dom.createMorphAt(element9,4,4);
          content(env, morph0, context, "item.stock.sDes");
          attribute(env, attrMorph0, element6, "class", concat(env, [subexpr(env, context, "getUpDownStyle", [get(env, context, "item.stock.chg"), "ticker-item fa up ticker-green", "ticker-item fa down ticker-red", ""], {})]));
          inline(env, morph1, context, "formatNumber", [get(env, context, "item.stock.adjustedLtp"), get(env, context, "item.stock.deci")], {});
          inline(env, morph2, context, "formatNumberPercentage", [get(env, context, "item.stock.pctChg")], {});
          content(env, morph3, context, "app.lang.labels.volume");
          inline(env, morph4, context, "formatNumber", [get(env, context, "item.stock.vol"), 0], {});
          content(env, morph5, context, "app.lang.labels.trades");
          inline(env, morph6, context, "formatNumber", [get(env, context, "item.stock.trades"), 0], {});
          content(env, morph7, context, "app.lang.labels.lastUpdated");
          inline(env, morph8, context, "formatToDate", [get(env, context, "item.stock.prevD")], {});
          inline(env, morph9, context, "formatToTime", [get(env, context, "item.stock.prevT"), get(env, context, "item.exchange.tzo")], {});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","item");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","margin-small-left margin-small-right text-white bold");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","value no-margin");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item value no-margin");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item value no-margin");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","ticker-item value no-margin");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(": ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element0, [5]);
          var element3 = dom.childAt(element0, [7]);
          var element4 = dom.childAt(element0, [9]);
          var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),1,1);
          var attrMorph0 = dom.createAttrMorph(element1, 'class');
          var morph1 = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
          var morph2 = dom.createMorphAt(dom.childAt(element1, [3]),0,0);
          var morph3 = dom.createMorphAt(element2,0,0);
          var morph4 = dom.createMorphAt(element2,2,2);
          var morph5 = dom.createMorphAt(element3,0,0);
          var morph6 = dom.createMorphAt(element3,2,2);
          var morph7 = dom.createMorphAt(element4,0,0);
          var morph8 = dom.createMorphAt(element4,2,2);
          var morph9 = dom.createMorphAt(element4,4,4);
          content(env, morph0, context, "item.stock.sDes");
          attribute(env, attrMorph0, element1, "class", concat(env, [subexpr(env, context, "getUpDownStyle", [get(env, context, "item.stock.chg"), "ticker-item fa up ticker-green", "ticker-item fa down ticker-red", ""], {})]));
          inline(env, morph1, context, "formatNumber", [get(env, context, "item.stock.adjustedLtp"), get(env, context, "item.stock.deci")], {});
          inline(env, morph2, context, "formatNumberPercentage", [get(env, context, "item.stock.pctChg")], {});
          content(env, morph3, context, "app.lang.labels.volume");
          inline(env, morph4, context, "formatNumber", [get(env, context, "item.stock.vol"), 0], {});
          content(env, morph5, context, "app.lang.labels.trades");
          inline(env, morph6, context, "formatNumber", [get(env, context, "item.stock.trades"), 0], {});
          content(env, morph7, context, "app.lang.labels.lastUpdated");
          inline(env, morph8, context, "formatToDate", [get(env, context, "item.stock.prevD")], {});
          inline(env, morph9, context, "formatToTime", [get(env, context, "item.stock.prevT"), get(env, context, "item.exchange.tzo")], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","tickerDiv stock-ticker margin-medium-top");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tickerTwo ticker ticker-animation transform anim-play");
        dom.setAttribute(el3,"id","tickerTwo");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","tickerOne ticker ticker-animation transform anim-play");
        dom.setAttribute(el3,"id","tickerOne");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element10 = dom.childAt(fragment, [0, 1]);
        var morph0 = dom.createMorphAt(dom.childAt(element10, [1]),1,1);
        var morph1 = dom.createMorphAt(dom.childAt(element10, [3]),1,1);
        block(env, morph0, context, "each", [get(env, context, "tickerTwoItems")], {"keyword": "item"}, child0, null);
        block(env, morph1, context, "each", [get(env, context, "tickerOneItems")], {"keyword": "item"}, child1, null);
        return fragment;
      }
    };
  }()));

});