define('ir-app/helpers/helpers', ['ember', 'ir-app/config/user-settings', 'ir-app/utils/utils', 'ir-app/components/table/table-component', 'ir-app/components/table/portal-table-component', 'ir-app/components/bootstrap-dropdown-select', 'ir-app/components/bootstrap-icon-dropdown', 'ir-app/components/link-dropdown', 'ir-app/components/widget-header', 'ir-app/models/price/price-constants', 'ir-app/utils/validators', 'ir-app/models/shared/shared-service'], function (Ember, userSettings, utils, TableComponent, PortalTableComponent, BootstrapDropdownSelect, BootstrapIconDropdown, LinkDropdown, WidgetHeader, PriceConstant, validators, sharedService) {

  'use strict';

  Ember['default'].Handlebars.helper('divideNumber', function (value, decimalPlaces) {
    var noOfDecimals = !isNaN(decimalPlaces) ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var valNumeric = Number(value);
    var val = valNumeric === 0 || isNaN(valNumeric) ? 0.0 : valNumeric;

    var formatted = utils['default'].formatters.divideNumber(val, noOfDecimals);

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Multiply numbers by a factor.
   * @param value Number to format
   * @param factor Multiplication factor
   * @param decimalPlaces Number of decimal places
   * @returns {string} Number multiplied and format to given decimal places
   */
  Ember['default'].Handlebars.helper('multiplyNumber', function (value, factor, decimalPlaces) {
    var noOfDecimals = !isNaN(decimalPlaces) ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var val = value === 0 || isNaN(value) ? 0.0 : value;

    var formatted = val ? utils['default'].formatters.multiplyNumber(val, factor, noOfDecimals) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Multiply numbers by a factor and percentage sign.
   * @param value Number to format
   * @param factor Multiplication factor
   * @param decimalPlaces Number of decimal places
   * @returns {string} Number multiplied and format to given decimal places
   */
  Ember['default'].Handlebars.helper('multiplyNumberPercentage', function (value, factor, decimalPlaces) {
    var noOfDecimals = !isNaN(decimalPlaces) ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var val = value === 0 || isNaN(value) ? 0.0 : value;

    var formatted = val ? utils['default'].formatters.multiplyNumberPercentage(val, factor, noOfDecimals) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  Ember['default'].Handlebars.helper('inMillions', function (value, decimalPlace, hideUnits) {
    var decimalPlaces = Number(decimalPlace);
    var noOfDecimals = !isNaN(decimalPlaces) ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var valNumeric = Number(value);
    var val = valNumeric === 0 || isNaN(valNumeric) ? 0.0 : valNumeric;

    var formatted = utils['default'].formatters.inMillions(val, noOfDecimals, hideUnits);

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Format number to given decimal places and separate each 3 digits by commas
   * @param value Number to format
   * @param decimalPlaces Number of decimal places
   * @returns {*} Number formatted to given decimal places and commas added
   */
  Ember['default'].Handlebars.helper('formatNumber', function (value, decimalPlaces) {
    var noOfDecimals = !isNaN(decimalPlaces) && decimalPlaces >= 0 ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var val = value === 0 || isNaN(value) ? 0.0 : value;

    var formatted = utils['default'].formatters.formatNumber(val, noOfDecimals);

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Format number to given decimal places and separate each 3 digits by commas, if NaN returns "--"
   * @param value Number to format
   * @param decimalPlaces Number of decimal places
   * @returns {*} Number formatted to given decimal places and commas added
   */
  Ember['default'].Handlebars.helper('formatNumberwithIsNaN', function (value, decimalPlaces) {
    var noOfDecimals = !isNaN(decimalPlaces) && decimalPlaces >= 0 ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var val = 0.0;
    var formatted = 0.0;
    if (value === 0) {
      val = 0.0;
      formatted = utils['default'].formatters.formatNumber(val, noOfDecimals);
    } else if (value === 'NaN') {
      formatted = utils['default'].formatters.noValue;
    } else {
      val = value;
      formatted = utils['default'].formatters.formatNumber(val, noOfDecimals);
    }
    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Format number to given decimal places and separate each 3 digits by commas if value is 0 returns N/A
   * @param value Number to format
   * @param decimalPlaces Number of decimal places
   * @returns {*} Number formatted to given decimal places and commas added or  if value is zero N/A
   */
  Ember['default'].Handlebars.helper('formatNumberOrNA', function (value, decimalPlaces) {
    if (value) {
      var noOfDecimals = !isNaN(decimalPlaces) && decimalPlaces >= 0 ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
      var val = value === 0 || isNaN(value) ? 0.0 : value;

      var formatted = utils['default'].formatters.formatNumber(val, noOfDecimals);

      return new Ember['default'].Handlebars.SafeString(formatted);
    } else {
      if (isNaN(value) || Ember['default'].$.trim(value) === '') {
        return userSettings['default'].displayFormat.noValue;
      } else {
        return 'N/A';
      }
    }
  });

  /**
   * Format number to given decimal places and separate each 3 digits by commas and add percentage symbol (%)
   * @param value Number to format
   * @param decimalPlaces Number of decimal places
   * @param {optional} if provided value will be multiply by given factor
   * @param  isSign {optional} if provided multiplied value positive and need to append sign
   * @returns {*} Number formatted to given decimal places and commas and percentage symbol (%) added
   */
  Ember['default'].Handlebars.helper('formatNumberPercentage', function (value, decimalPlaces, factor, isSign) {
    if (isNaN(value) || Ember['default'].$.trim(value) === '') {
      return userSettings['default'].displayFormat.noValue;
    }
    var noOfDecimals = !isNaN(decimalPlaces) ? decimalPlaces : userSettings['default'].displayFormat.decimalPlaces;
    var val = factor !== undefined ? value * factor : value;
    var formatted = '';
    if (isSign === true && val > 0) {
      formatted = ['+', utils['default'].formatters.formatNumberPercentage(val, noOfDecimals)].join('');
    } else {
      formatted = utils['default'].formatters.formatNumberPercentage(val, noOfDecimals);
    }

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Format date to display format
   * Display format will be taken from application configuration file
   * @param date Date String, Format: yyyyMMdd
   * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
   */
  Ember['default'].Handlebars.helper('formatToDate', function (date, offset) {
    var timeOffset = !isNaN(offset) ? offset : 0;
    var formatted = date && date.length >= 8 ? utils['default'].formatters.formatToDate(date, timeOffset) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Get the year
   * @param date Date String, Format: yyyyMMdd
   */
  Ember['default'].Handlebars.helper('getYearComponent', function (date) {
    var formatted = date && date.length >= 8 ? utils['default'].formatters.getYearComponent(date) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Get the month
   * @param date Date String, Format: yyyyMMdd
   * @param labels app.lang.labels
   * @param isShorten {true,false}, If true return short format. ie: Jan
   */
  Ember['default'].Handlebars.helper('getMonthComponent', function (date, labels, isShorten) {
    var formatted = date && date.length >= 8 ? utils['default'].formatters.getMonthComponent(date, labels, isShorten) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Get the date
   * @param date Date String, Format: yyyyMMdd
   */
  Ember['default'].Handlebars.helper('getDateComponent', function (date) {
    var formatted = date && date.length >= 8 ? utils['default'].formatters.getDateComponent(date) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Get the time
   * @param dateTime Date String, Format: yyyyMMdd HHmmss
   */
  Ember['default'].Handlebars.helper('getTimeComponent', function (dateTime) {
    var formatted = dateTime && dateTime.length >= 8 ? utils['default'].formatters.getTimeComponent(dateTime) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Format time to display format
   * Display format will be taken from application configuration file
   * @param time Time String, Format: HHmmss
   * @param offset Offset, Format: x.y (Ex: 2, 2.0, 5.5)
   */
  Ember['default'].Handlebars.helper('formatToTime', function (time, offset) {
    var timeOffset = !isNaN(offset) ? offset : 0;
    var formatted = time && time.length >= 6 ? utils['default'].formatters.formatToTime(time, timeOffset) : userSettings['default'].displayFormat.noValue;

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Format Date Object to display Date format
   * Display format will be taken from application configuration file
   * @param date Date Object
   */
  Ember['default'].Handlebars.helper('formatToDisplayDate', function (date) {
    var formatted = date ? utils['default'].formatters.formatDateToDisplayOnlyDate(date) : '';
    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * Get the date
   * @param date Date String, Format: yyyyMMdd
   * @param labels app.lang.labels
   * @param isShorten {true,false}, If true return short format. ie: Jan
   * @param dayClass class for day
   * @param monthClass class for month
   * @param YearClass class for year
   * return formated date
   */
  Ember['default'].Handlebars.helper('displayFormatDate', function (date, labels, isShorten, dayClass, monthClass, YearClass) {

    if (date && date.length >= 8) {
      var tagOpen = '<span class="';
      var tagClose = '">';
      var closeElement = '</span>';
      var day = tagOpen + dayClass + tagClose + utils['default'].formatters.getDateComponent(date) + closeElement;
      var month = tagOpen + monthClass + tagClose + utils['default'].formatters.getMonthComponent(date, labels, isShorten) + closeElement;
      var year = tagOpen + YearClass + tagClose + utils['default'].formatters.getYearComponent(date) + closeElement;
      var formattedDate = [day, month, year].join(' ');

      return new Ember['default'].Handlebars.SafeString(formattedDate);
    } else {
      return userSettings['default'].displayFormat.noValue;
    }
  });

  /**
   * Get the date
   * @param date Date String, Format: yyyyMMdd HHmmss
   * @param labels app.lang.labels
   * @param isShorten {true,false}, If true return short format. ie: Jan
   * return formated date
   */
  Ember['default'].Handlebars.helper('displayFormatDateTime', function (date, labels, isShorten, isDateOnly) {

    if (date && date.length >= 8) {
      return new Ember['default'].Handlebars.SafeString(utils['default'].formatters.displayFormatDateTime(date, labels, isShorten, isDateOnly));
    } else {
      return userSettings['default'].displayFormat.noValue;
    }
  });

  Ember['default'].Handlebars.helper('displayFormatDateTimeAdjusted', function (date, labels, offset) {
    if (date && date.length >= 8) {
      var adjustedDateTime = utils['default'].formatters.formatToDefaultDateTime(date, offset);
      if (date.length === 8) {
        return new Ember['default'].Handlebars.SafeString(utils['default'].formatters.displayFormatDateTime(adjustedDateTime, labels, true, true));
      } else {
        return new Ember['default'].Handlebars.SafeString(utils['default'].formatters.displayFormatDateTime(adjustedDateTime, labels, true, false));
      }
    } else {
      return userSettings['default'].displayFormat.noValue;
    }
  });

  /**
   * Highlight Pattern matching text
   * @param
   * returns string with the concatenated css & html
   */
  Ember['default'].Handlebars.helper('highlightMatchingText', function (text, phrase) {
    var highlightedText = text.replace(new RegExp(phrase, 'gi'), function (str) {
      return '<span class="colour-2">' + str + '</span>';
    });

    return new Ember['default'].Handlebars.SafeString(highlightedText);
  });

  /**
   * Highlight Pattern matching text, can apply custom css class
   * @param text text to apply css
   * @param phrase matching text
   * @param cssClass css class to apply
   * returns string with the concatenated css & html
   */
  Ember['default'].Handlebars.helper('applyCssMatchingText', function (text, phrase, cssClass) {
    var highlightedText = text;
    if (text !== undefined) {
      highlightedText = text.replace(new RegExp(phrase, 'gi'), function (str) {
        return '<span class=' + cssClass + '>' + str + '</span>';
      });
    }
    return new Ember['default'].Handlebars.SafeString(highlightedText);
  });

  Ember['default'].Handlebars.registerBoundHelper('dataTextField', function (data, key, formatter) {
    var value = data.get(key);
    if (formatter === 'formatNumber') {
      return utils['default'].formatters.formatNumber(value, 0);
    } else if (formatter === 'formatNumberPercentage') {
      return utils['default'].formatters.formatNumberPercentage(value, 2);
    } else if (formatter === 'divideNumber') {
      return utils['default'].formatters.divideNumber(value, 1);
    } else if (formatter === 'formatNumberWithDeci') {
      return utils['default'].formatters.formatNumber(value, 2);
    } else {
      return value;
    }
  });

  Ember['default'].Handlebars.helper('fontColor', function (data, key, fontColor) {

    if (data[key] < 0 && fontColor === 'redOrGreen') {
      return 'red';
    } else if (data[key] > 0 && fontColor === 'redOrGreen') {
      return 'green';
    } else if (data[key] === 0 && fontColor === 'redOrGreen') {
      return 'colour-normal';
    } else {
      return fontColor;
    }
  });

  Ember['default'].Handlebars.registerHelper('isOdd', function (options) {
    if (options.data.view.contentIndex % 2 === 1) {
      return new Ember['default'].Handlebars.SafeString(options.fn(this));
    }

    return new Ember['default'].Handlebars.SafeString(options.inverse(this));
  });

  Ember['default'].Handlebars.registerHelper('isNotCurrentLang', function (options) {
    if (options.data.view.content.ln !== sharedService['default'].userSettings.currentLanguage) {
      options.fn(this);
    } else {
      if (Ember['default'].$.isFunction(options.inverse)) {
        options.inverse(this);
      }
    }
  });

  Ember['default'].Handlebars.helper('isAvailableWidgets', function (inst) {
    return utils['default'].AssetTypes.isEquity(inst) || utils['default'].AssetTypes.isEtf(inst);
  });

  /**
   * Get the duration
   * @param date Date String, Format: youtube format
   */
  Ember['default'].Handlebars.helper('getYoutubeTimeDuration', function (youtubeTime) {
    var today = new Date();
    var uploadDate = new Date().setTime(Date.parse(youtubeTime));
    var formatted = youtubeTime && youtubeTime.length >= 8 ? utils['default'].formatters.timeDifference(today, uploadDate) : '';

    return new Ember['default'].Handlebars.SafeString(formatted);
  });

  /**
   * return current domain of the app
   */
  Ember['default'].Handlebars.helper('domain', function () {
    var url = window.location.href;
    var domain;
    if (url.indexOf('://') > -1) {
      domain = ['http://', url.split('/')[2]].join('');
    } else {
      domain = url.split('/')[0];
    }
    return domain;
  });

  /**
   * Check if a number is negative and returns the appropriate style class
   * @param value Number to check
   * @param style CSS Class for positive
   * @param style CSS Class for negative
   * @param style CSS Class for nutral
   * @returns style Appropriate style
   */
  Ember['default'].Handlebars.helper('getUpDownStyle', function (value, positive, negative, nutral) {
    var val = value === 0 || isNaN(value) ? 0.0 : value;
    return val > 0 ? positive : val < 0 ? negative : nutral;
  });

  /**
   * Check the market status and returns the appropriate style class
   * @param market status
   * @param style CSS Class for pre open
   * @param style CSS Class for open
   * @param style CSS Class for pre close
   * @param style CSS Class for close
   * @returns style Appropriate for the market status
   */
  Ember['default'].Handlebars.helper('getStyleforMktStatus', function (value, preOpen, open, close, preClose) {
    var val = value > 4 || value < 1 || isNaN(value) ? 1 : value;
    return val === 1 ? preOpen : val === 2 ? open : val === 3 ? close : preClose;
  });

  /**
   * Check the distressed Company Flag Status for a given company. This value is available ony for TDWL
   * @param value Number to check
   * @returns style Appropriate style
   */
  Ember['default'].Handlebars.helper('getFlagStyle', function (value) {
    if (value === '3') {
      return 'fa fa-flag red-flag';
    } else if (value === '2') {
      return 'fa fa-flag orange-flag';
    } else if (value === '1') {
      return 'fa fa-flag yellow-flag';
    } else {
      return '';
    }
  });

  /**
   * Check the distressed Company Flag tooltip for a given company. This value is available ony for TDWL
   * @param value Number to check
   * @param labels Set of labels available for language
   * @returns text Appropriate tooltip
   */
  Ember['default'].Handlebars.helper('getDCFSFlagToolTipText', function (value, labels) {
    if (value === '3') {
      return labels['dcfsFlagYellowText'];
    } else if (value === '2') {
      return labels['dcfsFlagOrangeText'];
    } else if (value === '1') {
      return labels['dcfsFlagWhiteText'];
    } else {
      return '';
    }
  });

  /**
   * Get the currency pairs
   * @param Currency Pair String(USDEUR)
   * @returns Currency Pair Format: USD-EUR
   */
  Ember['default'].Handlebars.helper('formatCurreyPair', function (currencyPair) {
    var len = currencyPair.length;
    return currencyPair.substring(0, len - 3) + '-' + currencyPair.substring(len - 3);
  });

  /**
   * get month String for the given int. ie: for 1 return January
   * @param isShorten     {true,false}  if true return short format. ie: Jan
   * @returns {string} month as a String
   */
  Ember['default'].Handlebars.helper('getMonthStrFromInt', function (labels, monthInt, isShorten) {
    return utils['default'].formatters.getMonthStrFromInt(labels, monthInt, isShorten);
  });

  /**
   * apply placeholders for the given string
   * @placeHoldingStr place holding String
   * @args inputs for the placeholders by the respective order
   * @return
   *
   */
  Ember['default'].Handlebars.helper('applyPlaceHolders', function (placeHoldingStr) {
    return utils['default'].formatters.applyPlaceHolders(placeHoldingStr, arguments);
  });

  /**
   * validate a given string and return a default value if not available
   * @params input string
   * @return same string or the default value
   */
  Ember['default'].Handlebars.helper('stringValidator', function (str) {
    return validators['default'].isAvailable(str) ? str : userSettings['default'].displayFormat.noValue;
  });

  /**
   * Append Currency To Value
   * @params input value as Number
   * @param input decimalPlaces number of decimal palces
   * @params input currency as currency code
   * @return  format value with currency append
   */
  Ember['default'].Handlebars.helper('appendCurrencyToValue', function (value, currency, decimalPlaces) {
    return utils['default'].formatters.appendCurrencyToValue(value, currency, decimalPlaces);
  });

  /**
   * get multi lingual market statue string
   * @param labels        {app.lang.labels}
   * @param label key
   * @param ifNoLabel     if label is not available then this will be returned, but this is optional
   * @returns {string}
   */
  Ember['default'].Handlebars.helper('getLabel', function (labels, labelKey, ifNoLabel) {
    return labels[labelKey] ? labels[labelKey] : ifNoLabel ? ifNoLabel : labelKey;
  });

  /**
   * get multi lingual market statue string
   * @param labels     {app.lang.labels}
   * @param status     market status (integer)
   * @returns {string} market status display string
   */
  Ember['default'].Handlebars.helper('getMarketStatus', function (labels, status) {
    var statString = '';

    switch (status) {
      case PriceConstant['default'].MarketStatus.Initialising:
        statString = labels.mktInitialising;
        break;

      case PriceConstant['default'].MarketStatus.PreOpen:
        statString = labels.mktPreOpen;
        break;

      case PriceConstant['default'].MarketStatus.Open:
        statString = labels.mktOpen;
        break;

      case PriceConstant['default'].MarketStatus.Close:
        statString = labels.mktClose;
        break;

      case PriceConstant['default'].MarketStatus.PreClose:
        statString = labels.mktPreClose;
        break;

      case PriceConstant['default'].MarketStatus.OpenSuspended:
        statString = labels.mktOpenSuspended;
        break;

      case PriceConstant['default'].MarketStatus.PreCloseSuspended:
        statString = labels.mktPreCloseSuspended;
        break;

      case PriceConstant['default'].MarketStatus.CloseSuspended:
        statString = labels.mktCloseSuspended;
        break;

      case PriceConstant['default'].MarketStatus.PreOpenSuspended:
        statString = labels.mktPreOpenSuspended;
        break;

      case PriceConstant['default'].MarketStatus.MovingToPreOpen:
        statString = labels.mktMovingToPreOpen;
        break;

      case PriceConstant['default'].MarketStatus.MovingToOpen:
        statString = labels.mktMovingToOpen;
        break;

      case PriceConstant['default'].MarketStatus.MovingToPreClose:
        statString = labels.mktMovingToPreClose;
        break;

      case PriceConstant['default'].MarketStatus.MovingToClose:
        statString = labels.mktMovingToClose;
        break;

      case PriceConstant['default'].MarketStatus.PreCloseWithTrades:
        statString = labels.mktPreCloseWithTrades;
        break;

      case PriceConstant['default'].MarketStatus.CloseWithTrades:
        statString = labels.mktCloseWithTrades;
        break;

      case PriceConstant['default'].MarketStatus.TradingAtLast:
        statString = labels.mktTradingAtLast;
        break;

      case PriceConstant['default'].MarketStatus.IntermediateClose:
        statString = labels.mktIntermediateClose;
        break;

      case PriceConstant['default'].MarketStatus.PreCloseAdjustment:
        statString = labels.mktPreCloseAdjustment;
        break;

      default:
        statString = userSettings['default'].displayFormat.noValue;
        break;
    }

    return statString;
  });

  /**
   * Append Css Class if text available
   * @params input value as Text
   * @param input css class if text available
   * @params input css class if text not-available
   * @return  relevent Css class according to text availability
   */
  Ember['default'].Handlebars.helper('textAvailability', function (str, isTextAvl, isTextNotAvl) {
    return validators['default'].isAvailable(str) ? isTextAvl : isTextNotAvl;
  });

  Ember['default'].Handlebars.helper('table-component', TableComponent['default']);
  Ember['default'].Handlebars.helper('bootstrap-dropdown-select', BootstrapDropdownSelect['default']);
  Ember['default'].Handlebars.helper('bootstrap-icon-dropdown', BootstrapIconDropdown['default']);
  Ember['default'].Handlebars.helper('widget-header', WidgetHeader['default']);
  Ember['default'].Handlebars.helper('link-dropdown', LinkDropdown['default']);
  Ember['default'].Handlebars.helper('portal-table-component', PortalTableComponent['default']);

});