define('ir-app/config/layout-config', ['exports'], function (exports) {

    'use strict';

    exports['default'] = {
        mobile_gfh_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'home',
                    widgetTitle: 'home',
                    icon: 'fa fa-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: true,
                    def: true,

                    tab: [{
                        id: 1,
                        title: 'home',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-three-layout',
                        w: [{ id: 1, wn: 'ir-mobile.common-widgets.stock-header' }, { id: 2, wn: 'ir-mobile.quote-widgets.minified-quote' }, { id: 3, wn: 'ir-mobile.home-widgets.bottom-container' }]
                    }]
                }, {
                    id: 2,
                    title: 'quote',
                    widgetTitle: 'Quote',
                    icon: 'fa fa-balance-scale',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'quote',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-two-layout',
                        w: [{ id: 1, wn: 'ir-mobile.common-widgets.stock-header' }, { id: 2, wn: 'ir-mobile.quote-widgets.default-quote' }]
                    }]
                }, {
                    id: 3,
                    title: 'newsAnnouncement',
                    widgetTitle: 'newsAnnouncement',
                    icon: 'icon-megaphone-1',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'newsAnnouncement',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.news-announcement-widget.news-announcement' }]
                    }]
                }, {
                    id: 4,
                    title: 'eventCalendar',
                    widgetTitle: 'eventCalendar',
                    icon: 'icon-calendar',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'eventCalendar',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.event-calendar-widget.event-calendar' }]
                    }]
                }, {
                    id: 5,
                    title: 'historylookup',
                    widgetTitle: 'historylookup',
                    icon: 'fa fa-history',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'historylookup',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.history-lookup-widget.history-lookup' }]
                    }]
                }, {
                    id: 6,
                    title: 'chart',
                    widgetTitle: 'chart',
                    icon: 'fa fa-area-chart',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'chart',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.chart-widgets.chart-widget' }]
                    }]
                }, {
                    id: 7,
                    title: 'financialandratios',
                    widgetTitle: 'financialandratios',
                    icon: 'icon-pro-chart',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'is',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: '1', wn: 'ir-mobile.financial-ratio-widgets.financial-tabs' }]
                    }]
                }, {
                    id: 8,
                    title: 'documents',
                    widgetTitle: 'documents',
                    icon: 'icon-files',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'documents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.file-widgets.file-list' }]
                    }]
                }, {
                    id: 9,
                    title: 'investorCal',
                    widgetTitle: 'investorCal',
                    icon: 'fa fa-calculator',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'investorCal',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.calculator-widgets.investment-calculator' }]
                    }]
                }, {
                    id: 10,
                    title: 'mediaRoom',
                    widgetTitle: 'mediaRoom',
                    icon: 'icon icon-tv',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'mediaRoom',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.media-room-widgets.media-room' }]
                    }]
                }, {
                    id: 11,
                    title: 'settings',
                    widgetTitle: 'Settings',
                    icon: 'fa fa-cog',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,

                    tab: [{
                        id: 1,
                        title: 'Settings',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.settings-widgets.general-settings' }]
                    }]
                }]
            }
        },

        ir_gfh_calendarEvents_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'calendar-events',
                    widgetTitle: 'calendar-events',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'calendarEvents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.calendar-events.calendar-events-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isForExchange: false,
                                    pageSize: 20,
                                    loadAnnInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_companyprofile_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.company-profile-mini-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.company-widgets.company-info' }, { id: 3, wn: 'ir-mobile.company-widgets.company-management-info' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                2: {
                                    keys: {
                                        showBasicInfo: true,
                                        showContacts: true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_disclosures_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'documents',
                    widgetTitle: 'documents',
                    icon: 'icon-files',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'documents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.file-widgets.file-list' }]
                    }]
                }]
            }
        },
        ir_gfh_investmentcalculator_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'investorCal',
                    widgetTitle: 'investorCal',
                    icon: 'fa fa-calculator',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'investorCal',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.calculator-widgets.investment-calculator' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    showInSlider: false
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_newsannouncements_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'newsAnnouncement',
                    widgetTitle: 'newsAnnouncement',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcement',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }, {
                        id: 2,
                        title: 'news',
                        def: false,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    contentType: 1,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        },
                        2: {
                            w: {
                                1: {
                                    contentType: 2,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_ownersandsubsidiaries_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.two-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.company-widgets.company-subsidiaries-info' }, { id: 2, wn: 'ir-mobile.company-widgets.company-owners-info' }]
                    }]
                }]
            }
        },
        ir_gfh_stockoverview_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'stock-overview',
                    widgetTitle: 'stock-overview',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'stkOvw',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.stock-overview-compact-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.quote-widgets.side-bar-quote' }, { id: 3, wn: 'ir-mobile.chart-widgets.old-chart-widget' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isWidgetClickable: false,
                                    newStkOvw: true
                                },
                                3: {
                                    isPortalChart: true,
                                    compareSymbolEnabled: true,
                                    chartIntervalEnabled: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_insidertransactions_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'insiderTransactions',
                    widgetTitle: 'insiderTransactions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.insider-transaction-widgets.insider-transactions' }]
                    }]
                }]
            }
        },
        ir_gfh_corporateactions_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'corporateactions',
                    widgetTitle: 'corporateActions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'corporateactions',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-two-layout',
                        w: [{ id: 1, wn: 'ir-widgets.corporate-actions.capital-actions-list' }, { id: 2, wn: 'ir-widgets.corporate-actions.non-capital-action-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    pageSize: 20
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_financial_dfm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'announcement-body',
                    widgetTitle: 'announcement-body',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcementBody',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.financial-widgets.advance-financials' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    customFieldsForIS: undefined, // array of custom fields should be included here
                                    customFieldsForBS: undefined,
                                    customFieldsForCF: undefined,
                                    customFieldsForRatios: undefined,

                                    includeSnapshot: false, // this parameter can add or remove a tab
                                    includeIS: true,
                                    includeBS: true,
                                    includeCF: true,
                                    includeRatios: true,

                                    activeSnapshot: '', // this parameter can have string values as ->  'active' or ''
                                    activeIS: 'active',
                                    activeBS: '',
                                    activeCF: '',
                                    activeRatios: '',

                                    includeFromToFields: true,
                                    includePeriodBasis: true,
                                    includeViewBtn: true,
                                    includeBreakdown: true,
                                    includeValuationRatioSegment: true, // valuation ratio block can be add or removed from snapshot
                                    isDisclosureAvailable: false, // disclosure visibility can be changed
                                    includeCompareBtn: false, // visibility of compare btn can be controlled

                                    comparePgeURL: 'http://www.alarabiya.net/aswaq/financial/{{1}}/{{2}}', // this must be given by the client
                                    docDownloadURL: 'http://ip/ibe?sid?=sid&RT=87&DOC=', // PDFs are downloaded from this URL

                                    numberOFQuartersDisplayed: 4, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 4,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 1, des: 'yearly' }, { view: 2, des: 'quarterly' }, { view: 3, des: 'interim' }, { view: 4, des: 'ttm' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                }
                            }
                        }
                    }
                }
            }
        },

        ir_gfh_calendarEvents_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'calendar-events',
                    widgetTitle: 'calendar-events',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'calendarEvents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.calendar-events.calendar-events-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isForExchange: false,
                                    pageSize: 20,
                                    loadAnnInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_companyprofile_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.company-profile-mini-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.company-widgets.company-info' }, { id: 3, wn: 'ir-mobile.company-widgets.company-management-info' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                2: {
                                    keys: {
                                        showBasicInfo: true,
                                        showContacts: true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_disclosures_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'documents',
                    widgetTitle: 'documents',
                    icon: 'icon-files',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'documents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.file-widgets.file-list' }]
                    }]
                }]
            }
        },
        ir_gfh_investmentcalculator_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'investorCal',
                    widgetTitle: 'investorCal',
                    icon: 'fa fa-calculator',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'investorCal',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.calculator-widgets.investment-calculator' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    showInSlider: false
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_newsannouncements_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'newsAnnouncement',
                    widgetTitle: 'newsAnnouncement',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcement',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }, {
                        id: 2,
                        title: 'news',
                        def: false,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    contentType: 1,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        },
                        2: {
                            w: {
                                1: {
                                    contentType: 2,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_ownersandsubsidiaries_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.two-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.company-widgets.company-subsidiaries-info' }, { id: 2, wn: 'ir-mobile.company-widgets.company-owners-info' }]
                    }]
                }]
            }
        },
        ir_gfh_stockoverview_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'stock-overview',
                    widgetTitle: 'stock-overview',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'stkOvw',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.stock-overview-compact-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.quote-widgets.side-bar-quote' }, { id: 3, wn: 'ir-mobile.chart-widgets.old-chart-widget' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                3: {
                                    isPortalChart: true,
                                    compareSymbolEnabled: true,
                                    chartIntervalEnabled: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_insidertransactions_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'insiderTransactions',
                    widgetTitle: 'insiderTransactions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.insider-transaction-widgets.insider-transactions' }]
                    }]
                }]
            }
        },
        ir_gfh_corporateactions_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'corporateactions',
                    widgetTitle: 'corporateActions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'corporateactions',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-two-layout',
                        w: [{ id: 1, wn: 'ir-widgets.corporate-actions.capital-actions-list' }, { id: 2, wn: 'ir-widgets.corporate-actions.non-capital-action-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    pageSize: 20
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_financial_kse_gfh_sub_r: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'announcement-body',
                    widgetTitle: 'announcement-body',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcementBody',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.financial-widgets.advance-financials' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    customFieldsForIS: undefined, // array of custom fields should be included here
                                    customFieldsForBS: undefined,
                                    customFieldsForCF: undefined,
                                    customFieldsForRatios: undefined,

                                    includeSnapshot: false, // this parameter can add or remove a tab
                                    includeIS: true,
                                    includeBS: true,
                                    includeCF: true,
                                    includeRatios: true,

                                    activeSnapshot: '', // this parameter can have string values as ->  'active' or ''
                                    activeIS: 'active',
                                    activeBS: '',
                                    activeCF: '',
                                    activeRatios: '',

                                    includeFromToFields: true,
                                    includePeriodBasis: true,
                                    includeViewBtn: true,
                                    includeBreakdown: true,
                                    includeValuationRatioSegment: true, // valuation ratio block can be add or removed from snapshot
                                    isDisclosureAvailable: false, // disclosure visibility can be changed
                                    includeCompareBtn: false, // visibility of compare btn can be controlled

                                    comparePgeURL: 'http://www.alarabiya.net/aswaq/financial/{{1}}/{{2}}', // this must be given by the client
                                    docDownloadURL: 'http://ip/ibe?sid?=sid&RT=87&DOC=', // PDFs are downloaded from this URL

                                    numberOFQuartersDisplayed: 4, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 4,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 1, des: 'yearly' }, { view: 2, des: 'quarterly' }, { view: 3, des: 'interim' }, { view: 4, des: 'ttm' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                }
                            }
                        }
                    }
                }
            }
        },

        ir_gfh_calendarEvents_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'calendar-events',
                    widgetTitle: 'calendar-events',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'calendarEvents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.calendar-events.calendar-events-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isForExchange: false,
                                    pageSize: 20,
                                    loadAnnInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_companyprofile_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.company-profile-mini-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.company-widgets.company-info' }, { id: 3, wn: 'ir-mobile.company-widgets.company-management-info' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                2: {
                                    keys: {
                                        showBasicInfo: true,
                                        showContacts: true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_disclosures_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'documents',
                    widgetTitle: 'documents',
                    icon: 'icon-files',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'documents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.file-widgets.file-list' }]
                    }]
                }]
            }
        },
        ir_gfh_investmentcalculator_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'investorCal',
                    widgetTitle: 'investorCal',
                    icon: 'fa fa-calculator',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'investorCal',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.calculator-widgets.investment-calculator' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    showInSlider: false
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_newsannouncements_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'newsAnnouncement',
                    widgetTitle: 'newsAnnouncement',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcement',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }, {
                        id: 2,
                        title: 'news',
                        def: false,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    contentType: 1,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        },
                        2: {
                            w: {
                                1: {
                                    contentType: 2,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_ownersandsubsidiaries_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.two-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.company-widgets.company-subsidiaries-info' }, { id: 2, wn: 'ir-mobile.company-widgets.company-owners-info' }]
                    }]
                }]
            }
        },
        ir_gfh_stockoverview_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'stock-overview',
                    widgetTitle: 'stock-overview',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'stkOvw',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.stock-overview-compact-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.quote-widgets.side-bar-quote' }, { id: 3, wn: 'ir-mobile.chart-widgets.old-chart-widget' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isWidgetClickable: false,
                                    newStkOvw: true
                                },
                                3: {
                                    isPortalChart: true,
                                    compareSymbolEnabled: true,
                                    chartIntervalEnabled: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_insidertransactions_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'insiderTransactions',
                    widgetTitle: 'insiderTransactions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.insider-transaction-widgets.insider-transactions' }]
                    }]
                }]
            }
        },
        ir_gfh_corporateactions_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'corporateactions',
                    widgetTitle: 'corporateActions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'corporateactions',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-two-layout',
                        w: [{ id: 1, wn: 'ir-widgets.corporate-actions.capital-actions-list' }, { id: 2, wn: 'ir-widgets.corporate-actions.non-capital-action-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    pageSize: 20
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_financial_bse_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'announcement-body',
                    widgetTitle: 'announcement-body',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcementBody',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.financial-widgets.advance-financials' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    customFieldsForIS: undefined, // array of custom fields should be included here
                                    customFieldsForBS: undefined,
                                    customFieldsForCF: undefined,
                                    customFieldsForRatios: undefined,

                                    includeSnapshot: false, // this parameter can add or remove a tab
                                    includeIS: true,
                                    includeBS: true,
                                    includeCF: true,
                                    includeRatios: true,

                                    activeSnapshot: '', // this parameter can have string values as ->  'active' or ''
                                    activeIS: 'active',
                                    activeBS: '',
                                    activeCF: '',
                                    activeRatios: '',

                                    includeFromToFields: true,
                                    includePeriodBasis: true,
                                    includeViewBtn: true,
                                    includeBreakdown: true,
                                    includeValuationRatioSegment: true, // valuation ratio block can be add or removed from snapshot
                                    isDisclosureAvailable: false, // disclosure visibility can be changed
                                    includeCompareBtn: false, // visibility of compare btn can be controlled

                                    comparePgeURL: 'http://www.alarabiya.net/aswaq/financial/{{1}}/{{2}}', // this must be given by the client
                                    docDownloadURL: 'http://ip/ibe?sid?=sid&RT=87&DOC=', // PDFs are downloaded from this URL

                                    numberOFQuartersDisplayed: 4, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 4,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 1, des: 'yearly' }, { view: 2, des: 'quarterly' }, { view: 3, des: 'interim' }, { view: 4, des: 'ttm' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                }
                            }
                        }
                    }
                }
            }
        },

        // ADSM
        ir_gfh_calendarEvents_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'calendar-events',
                    widgetTitle: 'calendar-events',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'calendarEvents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.calendar-events.calendar-events-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isForExchange: false,
                                    pageSize: 20,
                                    loadAnnInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_companyprofile_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.company-profile-mini-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.company-widgets.company-info' }, { id: 3, wn: 'ir-mobile.company-widgets.company-management-info' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                2: {
                                    keys: {
                                        showBasicInfo: true,
                                        showContacts: true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_disclosures_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'documents',
                    widgetTitle: 'documents',
                    icon: 'icon-files',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'documents',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.file-widgets.file-list' }]
                    }]
                }]
            }
        },
        ir_gfh_investmentcalculator_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'investorCal',
                    widgetTitle: 'investorCal',
                    icon: 'fa fa-calculator',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'investorCal',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.calculator-widgets.investment-calculator' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    showInSlider: false
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_newsannouncements_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'newsAnnouncement',
                    widgetTitle: 'newsAnnouncement',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcement',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }, {
                        id: 2,
                        title: 'news',
                        def: false,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.announcement-widgets.announcements-full-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    contentType: 1,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        },
                        2: {
                            w: {
                                1: {
                                    contentType: 2,
                                    pageSize: 20,
                                    showCustomTitle: false,
                                    loadInPopup: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_ownersandsubsidiaries_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'companyProf',
                    widgetTitle: 'companyProf',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.two-by-one-layout',
                        w: [{ id: 1, wn: 'ir-mobile.company-widgets.company-subsidiaries-info' }, { id: 2, wn: 'ir-mobile.company-widgets.company-owners-info' }]
                    }]
                }]
            }
        },
        ir_gfh_stockoverview_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'stock-overview',
                    widgetTitle: 'stock-overview',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'stkOvw',
                        def: true,
                        css: 'active',
                        outlet: 'ir-widgets.tab-layouts.stock-overview-compact-layout',
                        w: [{ id: 1, wn: 'ir-mobile.quote-widgets.top-bar-quote' }, { id: 2, wn: 'ir-mobile.quote-widgets.side-bar-quote' }, { id: 3, wn: 'ir-mobile.chart-widgets.old-chart-widget' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    isWidgetClickable: false,
                                    newStkOvw: true
                                },
                                3: {
                                    isPortalChart: true,
                                    compareSymbolEnabled: true,
                                    chartIntervalEnabled: true
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_insidertransactions_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'insiderTransactions',
                    widgetTitle: 'insiderTransactions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'companyProf',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.insider-transaction-widgets.insider-transactions' }]
                    }]
                }]
            }
        },
        ir_gfh_corporateactions_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'corporateactions',
                    widgetTitle: 'corporateActions',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'corporateactions',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-two-layout',
                        w: [{ id: 1, wn: 'ir-widgets.corporate-actions.capital-actions-list' }, { id: 2, wn: 'ir-widgets.corporate-actions.non-capital-action-list' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    pageSize: 20
                                }
                            }
                        }
                    }
                }
            }
        },
        ir_gfh_financial_adsm_gfh: {
            layout: {
                mainContent: [{
                    id: 1,
                    title: 'announcement-body',
                    widgetTitle: 'announcement-body',
                    icon: 'icon-home',
                    layoutTemplate: { wn: 'symbol-container-controller' },
                    background: false,
                    def: false,
                    tab: [{
                        id: 1,
                        title: 'announcementBody',
                        def: true,
                        css: 'active',
                        outlet: 'ir-mobile.tab-layouts.one-by-one-layout',
                        w: [{ id: 1, wn: 'ir-widgets.financial-widgets.advance-financials' }]
                    }]
                }]
            },
            args: {
                1: {
                    tab: {
                        1: {
                            w: {
                                1: {
                                    customFieldsForIS: undefined, // array of custom fields should be included here
                                    customFieldsForBS: undefined,
                                    customFieldsForCF: undefined,
                                    customFieldsForRatios: undefined,

                                    includeSnapshot: false, // this parameter can add or remove a tab
                                    includeIS: true,
                                    includeBS: true,
                                    includeCF: true,
                                    includeRatios: true,

                                    activeSnapshot: '', // this parameter can have string values as ->  'active' or ''
                                    activeIS: 'active',
                                    activeBS: '',
                                    activeCF: '',
                                    activeRatios: '',

                                    includeFromToFields: true,
                                    includePeriodBasis: true,
                                    includeViewBtn: true,
                                    includeBreakdown: true,
                                    includeValuationRatioSegment: true, // valuation ratio block can be add or removed from snapshot
                                    isDisclosureAvailable: false, // disclosure visibility can be changed
                                    includeCompareBtn: false, // visibility of compare btn can be controlled

                                    comparePgeURL: 'http://www.alarabiya.net/aswaq/financial/{{1}}/{{2}}', // this must be given by the client
                                    docDownloadURL: 'http://ip/ibe?sid?=sid&RT=87&DOC=', // PDFs are downloaded from this URL

                                    numberOFQuartersDisplayed: 4, // number of default displaying quarters and years can be specified
                                    numberOFYearsDisplayed: 4,

                                    activateViewDropdown: false, // changing the view period will change the content also if this is true
                                    keyListView: [{ view: 1, des: 'yearly' }, { view: 2, des: 'quarterly' }, { view: 3, des: 'interim' }, { view: 4, des: 'ttm' }], // view types can be customized, A-1 Q-2 C-3 T-4
                                    enableDefaultDetailedView: false // initial breakdown can be selected, True - Expand view False - Collapsed view
                                }
                            }
                        }
                    }
                }
            }
        }

    };

});