define('ir-app/templates/ir-widgets/factsheet-widget/company-details', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.1",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" %");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var morph0 = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
          var morph1 = dom.createMorphAt(dom.childAt(element0, [3]),0,0);
          content(env, morph0, context, "app.lang.labels.freeFltShr");
          content(env, morph1, context, "basicInfo.freeFltShr");
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.1",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","full-width");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col-xs-12 company-title no-padding");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 margin-medium-bottom");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5,"id","compName");
        dom.setAttribute(el5,"class","snap-company-name text-align-left left");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","name-span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"id","companyLogo");
        dom.setAttribute(el5,"class","company-logo");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","company-disc");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-xs-12");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"id","compDesc");
        dom.setAttribute(el6,"class","no-padding line-height-sm");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col-xs-12 no-padding");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","stock-performance");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5,"class","widget-title snap-font-size-2 snap-font-color-2 no-margin-top margin-small-bottom");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","col-xs-12 stock-data");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","row");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-4 margin-medium-bottom");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("table");
        dom.setAttribute(el7,"class","performance");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("tbody");
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" - ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-4 margin-medium-bottom");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("table");
        dom.setAttribute(el7,"class","performance");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("tbody");
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        dom.setAttribute(el10,"class","sheet-nowrap");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        dom.setAttribute(el10,"class","long-text-cust");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col-xs-4 margin-medium-bottom");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("table");
        dom.setAttribute(el7,"class","performance");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("tbody");
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tr");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("td");
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, content = hooks.content, get = hooks.get, inline = hooks.inline, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3, 1]);
        var element4 = dom.childAt(element3, [3, 1]);
        var element5 = dom.childAt(element4, [1, 1, 1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element5, [5]);
        var element9 = dom.childAt(element5, [7]);
        var element10 = dom.childAt(element5, [9]);
        var element11 = dom.childAt(element5, [11]);
        var element12 = dom.childAt(element5, [13]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element4, [3, 1, 1]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [3]);
        var element17 = dom.childAt(element14, [5]);
        var element18 = dom.childAt(element14, [7]);
        var element19 = dom.childAt(element14, [9]);
        var element20 = dom.childAt(element14, [11]);
        var element21 = dom.childAt(element14, [13]);
        var element22 = dom.childAt(element4, [5, 1, 1]);
        var element23 = dom.childAt(element22, [1]);
        var element24 = dom.childAt(element22, [3]);
        var element25 = dom.childAt(element22, [7]);
        var element26 = dom.childAt(element22, [9]);
        var element27 = dom.childAt(element22, [11]);
        var element28 = dom.childAt(element22, [13]);
        var morph0 = dom.createMorphAt(dom.childAt(element2, [1, 1, 0]),0,0);
        var morph1 = dom.createUnsafeMorphAt(dom.childAt(element2, [3, 1]),0,0);
        var morph2 = dom.createMorphAt(dom.childAt(element2, [5, 1, 1]),0,0);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [1, 1, 1]),0,0);
        var morph4 = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        var morph5 = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
        var morph6 = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
        var morph7 = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
        var morph8 = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        var morph9 = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
        var morph10 = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
        var morph11 = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
        var morph12 = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
        var morph13 = dom.createMorphAt(dom.childAt(element10, [3]),0,0);
        var morph14 = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
        var morph15 = dom.createMorphAt(dom.childAt(element11, [3]),0,0);
        var morph16 = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
        var morph17 = dom.createMorphAt(element13,0,0);
        var morph18 = dom.createMorphAt(element13,2,2);
        var morph19 = dom.createMorphAt(dom.childAt(element15, [1]),0,0);
        var morph20 = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
        var morph21 = dom.createMorphAt(dom.childAt(element16, [1]),0,0);
        var morph22 = dom.createMorphAt(dom.childAt(element16, [3]),0,0);
        var morph23 = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
        var morph24 = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
        var morph25 = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
        var morph26 = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
        var morph27 = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
        var morph28 = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
        var morph29 = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
        var morph30 = dom.createMorphAt(dom.childAt(element20, [3]),0,0);
        var morph31 = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
        var morph32 = dom.createMorphAt(dom.childAt(element21, [3]),0,0);
        var morph33 = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
        var morph34 = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
        var morph35 = dom.createMorphAt(dom.childAt(element24, [1]),0,0);
        var morph36 = dom.createMorphAt(dom.childAt(element24, [3]),0,0);
        var morph37 = dom.createMorphAt(element22,5,5);
        var morph38 = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
        var morph39 = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
        var morph40 = dom.createMorphAt(dom.childAt(element26, [1]),0,0);
        var morph41 = dom.createMorphAt(dom.childAt(element26, [3]),0,0);
        var morph42 = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
        var morph43 = dom.createMorphAt(dom.childAt(element27, [3]),0,0);
        var morph44 = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
        var morph45 = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
        content(env, morph0, context, "basicInfo.compName");
        content(env, morph1, context, "image");
        content(env, morph2, context, "basicInfo.des");
        content(env, morph3, context, "app.lang.labels.stockInformation");
        content(env, morph4, context, "app.lang.labels.pctChgWeek");
        inline(env, morph5, context, "formatNumberPercentage", [get(env, context, "stock.pctChgW")], {});
        content(env, morph6, context, "app.lang.labels.pctChgMonth");
        inline(env, morph7, context, "formatNumberPercentage", [get(env, context, "stock.pctChgM")], {});
        content(env, morph8, context, "app.lang.labels.pctChg3Month");
        inline(env, morph9, context, "formatNumberPercentage", [get(env, context, "stock.pctChg3M")], {});
        content(env, morph10, context, "app.lang.labels.ytd");
        inline(env, morph11, context, "formatNumberPercentage", [get(env, context, "stock.pctYtd")], {});
        content(env, morph12, context, "app.lang.labels.oneYr");
        inline(env, morph13, context, "formatNumberPercentage", [get(env, context, "stock.pctChgY")], {});
        content(env, morph14, context, "app.lang.labels.lastTradedPrice");
        inline(env, morph15, context, "formatNumber", [get(env, context, "stock.adjustedLtp"), 2], {});
        content(env, morph16, context, "app.lang.labels.fiftyTwoWksRange");
        content(env, morph17, context, "stock.l52");
        content(env, morph18, context, "stock.h52");
        content(env, morph19, context, "app.lang.labels.exchange");
        content(env, morph20, context, "stock.exg");
        content(env, morph21, context, "app.lang.labels.sector");
        content(env, morph22, context, "stock.sec");
        content(env, morph23, context, "app.lang.labels.isin");
        content(env, morph24, context, "basicInfo.isin");
        content(env, morph25, context, "app.lang.labels.symbol");
        content(env, morph26, context, "stock.sym");
        content(env, morph27, context, "app.lang.labels.estbOn");
        inline(env, morph28, context, "formatToDate", [get(env, context, "basicInfo.estbOn")], {});
        content(env, morph29, context, "app.lang.labels.listDate");
        inline(env, morph30, context, "formatToDate", [get(env, context, "basicInfo.stkLstOn")], {});
        content(env, morph31, context, "app.lang.labels.auditor");
        content(env, morph32, context, "basicInfo.auditor");
        content(env, morph33, context, "app.lang.labels.currency");
        content(env, morph34, context, "stock.cur");
        content(env, morph35, context, "app.lang.labels.outShr");
        inline(env, morph36, context, "formatNumber", [get(env, context, "basicInfo.outStdShr"), 0], {});
        block(env, morph37, context, "unless", [get(env, context, "zeroFreeFltShr")], {}, child0, null);
        content(env, morph38, context, "app.lang.labels.parVal");
        inline(env, morph39, context, "formatNumber", [get(env, context, "basicInfo.parVal")], {});
        content(env, morph40, context, "app.lang.labels.pdCap");
        inline(env, morph41, context, "inMillions", [get(env, context, "basicInfo.paidCap"), 0], {});
        content(env, morph42, context, "app.lang.labels.mktCap");
        inline(env, morph43, context, "inMillions", [get(env, context, "stock.mktCap"), 0], {});
        content(env, morph44, context, "app.lang.labels.authCap");
        inline(env, morph45, context, "inMillions", [get(env, context, "basicInfo.authCap"), 0], {});
        return fragment;
      }
    };
  }()));

});