define('ir-app/models/shared/language/language-primary', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    lang: 'EN',
    obj: {
      labels: {
        mngtNBrdMbrs: 'Management & Board Members',
        daysRange: 'Days Range%',
        weeksRange52: '52W Range%',
        copyright: 'Copyright 2002-2016 DirectFN™ Ltd. All rights reserved',
        companyInfor: 'Company Information',
        lastUpdated: 'Last Updated',
        news: 'News',
        title: 'Title',
        newsAnnouncement: 'News & Announcements',
        market: 'Market',
        quote: 'Quote',
        topLoosersByPerChg: 'Top Losers By % Change',
        topLoosersByChg: 'Top Losers By Change',
        topGainersByPerChg: 'Top Gainers by % Change',
        topGainersByChg: 'Top Gainers by Change',
        mostActByVol: 'Most Active By Volume',
        mostActByTrades: 'Most Active By Trades',
        mostActByTurnover: 'Most Active By Turnover',
        companyProf: 'Company Profile',
        trend: 'Trend',
        dark: 'Dark',
        light: 'Light',
        ok: 'OK',
        yes: 'Yes',
        no: 'No',
        save: 'Save',
        cancel: 'Cancel',
        retry: 'Retry',
        search: 'Search',
        provider: 'Provider',
        startDate: 'Start Date',
        endDate: 'End Date',
        to: 'To',
        from: 'From',
        preferredTheme: 'Please select preferred theme',
        preferredLanguage: 'Please select preferred Language',
        pctChgWeek: '1 Week',
        pctChgMonth: '1 Month',
        pctChg3Month: '3 Months',
        pctChgYear: '1 Year %',
        avgVol30Days: '30D Avg. Volume',
        avgTovr30Days: '30D Avg. Turnover',
        price15minDelay: 'Prices are delayed by 15 minutes',
        home: 'Home',
        eventCalendar: 'Event Calendar',
        upComingEvents: 'Upcoming Events',
        pastEvents: 'Past Events',
        financials: 'Financials',
        historylookup: 'History Lookup',
        selectDate: 'Select the Date',
        noTradeDataMessage: 'No trade data for the date you requested. The nearest trading day is',
        selectedDate: 'Selected Date',
        financialandratios: 'Financials & Ratios',
        documents: 'Documents',
        is: 'IS',
        bs: 'BS',
        cf: 'CF',
        ratios: 'Ratios',
        period: 'Period',
        statementType: 'Statement Type',
        yearly: 'Yearly',
        quarterly: 'Quarterly',
        netIncome: 'Net Income',
        totalRevenue: 'Total Revenue',
        totalUWRevenue: 'Total Underwriting Revenue',
        operatingIncome: 'Operating Income',
        totalAssets: 'Total Assets',
        totalEquity: 'Total Equity',
        operatingActivities: 'Cash Flow From Operating Activities',
        investingActivities: 'Cash Flow From Investing Activities',
        investorCal: 'Investment Calculator',
        mediaRoom: 'Media Room',
        facebook: 'Facebook',
        twitter: 'TWITTER',
        youTube: 'YouTube',
        linkedIN: 'LinkedIn',
        stkPrice: 'Stock Price',
        results: 'Results',
        calc: 'Calculate',
        reInvstDivid: 'Reinvest Dividends',
        totDivid: 'Total Dividends',
        totReturn: 'Total Return',
        rtnCal: 'Return Calculator',
        rtnPercentage: '% Return',
        annualReturn: 'Annualized Return',
        numSharesPurchased: 'No. of shares purchased',
        sharesPurchased: 'Shares Purchased',
        or: 'OR',
        amountInvest: 'Amount Invested',
        investDate: 'Investment Date',
        addAllEventToCalender: 'Add all events to calendar',
        settings: 'Settings',
        invalidInputs: 'Invalid Input',
        marketStatus: 'Market Status',
        fiftyTwoWkH: '52wk High',
        fiftyTwoWkL: '52wk Low',
        w52highLow: '52 W High-Low',
        web: 'Website',
        couponFreq: 'Coupon freq.',
        lastQty: 'Last Qty',
        lastTradedDate: 'L.T. Date',
        lastTradedTime: 'L.T. Time',
        indGrp: 'Industry Group',
        subInd: 'Sub Industry',
        isin: 'ISIN Code',
        estbOn: 'Established Date',
        freeFltShr: 'Free Float Shares',
        authCap: 'Auth Capital',
        trdName: 'Trade Name',
        parVal: 'Par Value',
        auditor: 'Auditor',
        open: 'Open',
        peRatio: 'P/E Ratio',
        high: 'High',
        quantity: 'Quantity',
        listedShr: 'Listed Shares',
        mktCap: 'Market Cap',
        openInterest: 'Open Interest',
        outstandingAmount: 'Outstanding Amount',
        sherPrs: 'Percentage',
        announcement: 'Announcements',
        unchanged: 'Unchanged',
        side: 'Side',
        settlementDate: 'Settlement Date',
        bondType: 'Bond Type',
        dayCountMethod: 'Day Count Method',
        currency: 'Currency',
        currencies: 'Currencies',
        topGainers: 'Top Gainers',
        topLosers: 'Top Losers',
        totalBidQty: 'Total Bid Qty',
        totalOfferQty: 'Total Offer Qty.',
        volume: 'Volume',
        all: 'All',
        addr: 'Address',
        previousCouponDate: 'Previous Coupon Date',
        phn: 'Tel No',
        fax: 'Fax',
        owners: 'Owners',
        subsidiaries: 'Subsidiaries',
        lotSize: 'Lot Size',
        name: 'Name',
        offer: 'Offer',
        bid: 'Bid',
        bestOffer: 'Best Offer',
        bestBid: 'Best Bid',
        bidOfferRatio: 'Bid/Offer Ratio',
        offerQty: 'Offer Qty',
        bidQty: 'Bid Qty',
        description: 'Description',
        trades: 'Trades',
        preClosed: 'Prev. Close',
        symbol: 'Symbol',
        cashMap: '% Cash Map',
        chart: 'Chart',
        filter: 'Filter',
        close: 'Close',
        vWAP: 'VWAP',
        expiryDate: 'Expiry Date',
        strikePrice: 'Strike Price',
        couponRate: 'Coupon Rate',
        exchange: 'Exchange',
        time: 'Time',
        splits: 'Splits',
        turnover: 'Turnover',
        ups: 'UPs',
        down: 'Down',
        email: 'Email',
        price: 'Price',
        eps: 'EPS',
        low: 'Low',
        perChange: '% Chg',
        change: 'Chg',
        yield1: 'Yield',
        selectInd: 'Indicators',
        beta: 'Beta',
        dividendYield: 'Dividend Yield',
        todayRange: 'Today\'s Range',
        fiftyTwoWkRange: '52Wk Range',
        allPricesIn: 'All prices in',
        trailingTwelveMonths: 'Trailing 12 months',
        commodities: 'Commodities',
        listDate: 'Listed Date',
        finYrStart: 'Financial Year Start',
        finYrEnd: 'Financial Year End',
        contacts: 'Contacts',
        lastPrice: 'Last Price',
        companyStatistics: 'Company Statistics',
        outShr: 'Outstanding Shares',
        pdCap: 'Paid Capital',
        bookValuePerShare: 'Book Value Per Share',
        pbRatio: 'P/B Ratio',
        dividends: 'Dividends',
        totalValueofCashDividend: 'Total Value of Cash Dividend (Mn.)',
        payoutRatio: 'Payout Ratio',
        dividendPerShare: 'Dividend Per Share',
        bonusShares: 'Bonus Shares',
        announcedLast12Months: 'announced in last 12 months',
        naIndicatesMessage: 'N/A indicates no values have been announced in last 12 months',
        events: 'Events',
        dcfsFlagYellowText: 'Companies with accumulated losses of 100% or more of its capital',
        dcfsFlagOrangeText: 'Companies with accumulated losses of 75% to less than 100% of its capital',
        dcfsFlagWhiteText: 'Companies with accumulated losses of 50% to less than 75% of its capital',
        middleEast: 'Middle East',
        ytd: 'YTD %',
        noOfTrades: 'No Of Trades',
        mktCapMillion: 'Market Cap(M)',
        marketMovers: 'Market Movers',
        stkOvw: 'Stock Overview',
        monthJan: 'January',
        monthShortJan: 'Jan',
        monthFeb: 'February',
        monthShortFeb: 'Feb',
        monthMar: 'March',
        monthShortMar: 'Mar',
        monthApril: 'April',
        monthShortApril: 'April',
        monthMay: 'May',
        monthShortMay: 'May',
        monthJun: 'June',
        monthShortJun: 'Jun',
        monthJly: 'July',
        monthShortJly: 'July',
        monthAgst: 'August',
        monthShortAgst: 'Aug',
        monthSep: 'September',
        monthShortSep: 'Sep',
        monthOct: 'October',
        monthShortOct: 'Oct',
        monthNov: 'November',
        monthShortNov: 'Nov',
        monthDec: 'December',
        monthShortDec: 'Dec',
        chartStyleTitle: 'Chart Style',
        chartShowVol: 'Show Volume',
        chartIntraday: 'Intraday',
        chartHistory: 'History',
        chartEveryMinute: 'Every Minute',
        chartEvery5Minutes: 'Every 5 Minutes',
        chartEvery10Minutes: 'Every 10 Minutes',
        chartEvery15Minutes: 'Every 15 Minutes',
        chartEvery30Minutes: 'Every 30 Minutes',
        chartEvery60Minutes: 'Every 60 Minutes',
        chartDaily: 'Daily',
        chartWeekly: 'Weekly',
        chartMonthly: 'Monthly',
        chartQuarterly: 'Quarterly',
        chartYearly: 'Yearly',
        chartArea: 'Area',
        chartCandle: 'Candle',
        chartOHLC: 'OHLC',
        chartLine: 'Line',
        chartCandleWithTrend: 'Candle With Trend',
        international: 'International',
        paidUpCapital: 'Paid Up Capital',
        reprstFor: 'as a Representative for',
        reprstTo: 'as a Representative to',
        currencyConverter: 'Currency Converter',
        currencyAmount: 'Currency Amount',
        stocksTradedListed: 'Stocks Traded',
        allAreSpot: '*All are Spot Prices',
        majorShareholders: 'Major Shareholders',
        mktIndices: 'Market Indices',
        marketPulse: 'Market Pulse',
        arabMarkets: 'Arab Markets',
        globalMarkets: 'Global Markets',
        btnClear: 'Clear',
        category: 'Category',
        location: 'Location',
        relatedAnn: 'Related Announcement',
        fromDate: 'From Date',
        toDate: 'To Date',
        marketRatios: 'Market Ratios',
        relatedStory: 'Related Story',
        indexOverview: 'Overview',
        allStocks: 'All Stocks',
        indexDetails: 'Index Details',
        indexStats: 'Index Stats',
        stkExchange: 'Stock Exchange',
        indexName: 'Index Name',
        indexConst: 'Index Constituents',
        indexSummary: 'Index Summary',
        stksAccumLoss: 'Stocks with Accumulated Losses',
        sector: 'Sector',
        enterCompareSym: 'Compare Symbol',
        mktInitialising: 'Initializing',
        mktPreOpen: 'Pre Open',
        mktOpen: 'Open',
        mktClose: 'Closed',
        mktPreClose: 'Pre Closed',
        mktOpenSuspended: 'Open Suspended',
        mktPreCloseSuspended: 'PreClosed Suspended',
        mktCloseSuspended: 'Closed Suspended',
        mktPreOpenSuspended: 'PreOpen Suspended',
        mktMovingToPreOpen: 'Moving To PreOpen',
        mktMovingToOpen: 'Moving To Open',
        mktMovingToPreClose: 'Moving To PreClosed',
        mktMovingToClose: 'Moving To Close',
        mktPreCloseWithTrades: 'PreClosed',
        mktCloseWithTrades: 'Closed',
        mktTradingAtLast: 'Trade At Last',
        mktIntermediateClose: 'Session Close',
        mktPreCloseAdjustment: 'Pre-close Adjustment',
        status: 'Status',
        effectiveDate: 'Effective Date',
        capitalAction: 'Capital Actions',
        nonCapitalAction: 'Non Capital Actions',
        announcingDate: 'Announcing Date',
        completionDate: 'Completion Date',
        action: 'Action',
        factor: 'Factor',
        final: 'Final',
        proposed: 'Proposed',
        balanceSheet: 'Balance Sheet',
        cashFlow: 'Cash Flow',
        incomeStat: 'Income Statement',
        showTradesfor: 'Show Trades for',
        transactions: 'Transactions',
        count: 'Count',
        estimatedValue: 'Estimated Value',
        date: 'Date',
        insiderName: 'Insider Name',
        vwapPrice: 'VWAP Price',
        estdTrnsValue: 'Estimated Transaction Value',
        net: 'Net',
        buy: 'Buy',
        sell: 'Sell',
        ratioFootNote: 'All financial ratios are calculated based on the audited financial statements and not preliminary financial results',
        recordDate: 'Record Date',
        details: 'Details',
        dividendAmount: 'Dividend Amount',
        issuePrice: 'Issue Price',
        splitFactor: 'Split Factor',
        rightsEndDate: 'Rights End Date',
        sharesBefore: 'Number of Shares Before',
        sharesAfter: 'Number of Shares After',
        sharesChange: 'Number of Shares Change',
        parValBfr: 'Par Value Before',
        parValAtr: 'Par Value After',
        parValChange: 'Par Value Change',
        capChanges: 'Capital Changes',
        periodEndDate: 'Period End Date',
        approvalStatus: 'Approval Status',
        abnormalCd: 'Abnormal CD',
        cdYear: 'CD Year',
        cdPeriod: 'CD Period',
        oldPaid: 'Old Paid',
        newPaid: 'New Paid',
        cashPaid: 'Cash Paid',
        priceLimitRemoval: 'Price Limits Removal',
        numbTrsryStks: 'Number Treasury Stocks',
        cdRelatedPeriod: 'CD Related Period',
        cdRelatedPeriodName: 'CD Related Period Name',
        actionDetails: 'Action Details',
        financialStatements: 'Financial Statements',
        pressReleases: 'Press Releases',
        bMeetingDisclosures: 'Board Meeting Disclosures',
        aMeetingDisclosures: 'Assembly Meeting Disclosures',
        glossyReports: 'Glossy Reports',
        baselIII: 'Basel III',
        company: 'Company',
        actual: 'Actual',
        summary: 'Summary',
        view: 'View',
        calendar: 'Calendar',
        fiscal: 'Fiscal',
        breakdown: 'Breakdown',
        periodBasis: 'Period Basis',
        detailedView: 'Detailed View',
        interim: 'Interim',
        ttm: 'TTM',
        ratiosAndGrowth: 'Ratios and Growth Rates',
        foreignOwnership: 'Foreign Ownership Limits',
        qfiClientOwnership: 'QFI and QFI client ownership',
        totalForeignOwnership: 'Total Foreign Ownership',
        maxLimit: 'Maximum Limit',
        actualLimit: 'Actual Limit',
        selectMarket: 'Select Market',
        stockScreener: 'Stock Screener',
        divYieldPer: 'D.Yld %',
        type: 'Type',
        industry: 'Industry',
        corporateActions: 'Corporate Actions',
        valuationRatio: 'Valuation Ratio',
        ipo: 'IPO List',
        download: 'Download',
        currentDay: 'Current Day',
        changeLongDes: 'Change',
        selectSymbol: 'Select symbol',
        searchResults: 'Search Results',
        poweredBy: 'Powered by',
        previousDay: 'Previous Day',
        prospectus: 'Prospectus',
        sizeMillions: 'Size (Mn.)',
        offered: '% Offered',
        capitalIncrease: 'Capital Increase',
        screeningCriteria: 'Screening Criteria',
        compare: 'Compare',
        inMillions: 'In Millions',
        authCapital: 'Total Authorized Capital (Mn.)',
        latestQuarter: 'Latest available quarter',
        disclosure: 'Disclosure',
        aggregatedMktSummary: 'Aggregated Market Summary',
        CFFromInvestingActivities: 'Cash Flow From Investing Activities',
        CFFromOperatingActivities: 'Cash Flow From Operating Activities',
        noOfStocks: 'Number of Stocks',
        totAuthorizedCapital: 'Total Authorized Capital (Millions)',
        corporateActionType: 'Corporate Action Type',
        aggregatedSummary: 'Aggregated Market Summary',
        currentOwnership: 'Current Ownership',
        previousOwnership: 'Prev Day\'s Ownership',
        ratioComparison: 'Ratio Comparison',
        noResultsFound: 'No Results Found',
        itemsSelected: 'Items Selected',
        nothingSelected: 'Nothing Selected',
        selectAll: 'Select All',
        deselectAll: 'Deselect All',
        currentQuarter: 'Current Quarter',
        threeMonths: '3M',
        sixMonths: '6M',
        oneYear: '1Y',
        twoYears: '2Y',
        underPerform: 'Under Perform',
        hold: 'Hold',
        outPerform: 'Out Perform',
        currentQuarterConsensus: 'Current Quarter Consensus',
        threeMonthsConsensus: 'Three Months Consensus',
        sixMonthsConsensus: 'Six Months Consensus',
        oneYearConsensus: 'One Year Consensus',
        twoYearConsensus: 'Two Year Consensus',
        meanConsensus: 'Mean Consensus',
        recommendations: 'Recommendations',
        avgTargetPriceAdjusted: 'Average Target Price "Adjusted"',
        upsidePotential: 'Upside Potential',
        duration: 'Duration',
        providers: 'Providers',
        showValues: 'Show Values',
        adjForCorporateActions: 'Adjusted for Corporate Actions',
        asReported: 'As Reported',
        chgIndicator: 'Chg. Ind.',
        rec: 'Rec.',
        targetP: 'Target P.',
        upsideP: 'Upside P.',
        rep: 'Rep.',
        prevRepDate: 'Prev. Rep. Date',
        prevRec: 'Prev. Rec.',
        prevTargetP: 'Prev. Target P.',
        prevRep: 'Prev. Rep.',
        recChangeIndicator: 'Recommendation Change Indicator',
        recommendation: 'Recommendation',
        adjTargetPrice: 'Adjusted Target Price',
        rawTargetPrice: 'Raw Target Price',
        curUpsidePotential: 'Current Upside Potential',
        report: 'Report',
        prevReportDate: 'Previous Report Date',
        prevRecommendation: 'Previous Recommendation',
        prevTargetPrice: 'Previous Target Price',
        prevReport: 'Previous Report',
        indicateValuesAdjusted: 'Indicated values are adjusted',
        rtnOnEquity: 'Return on Equity %',
        rtnOnAssets: 'Return on Assets %',
        value: 'Value',
        investments: 'Investments',
        associates: 'Associates',
        shareHolders: 'Shareholders',
        corporate: 'Corporate',
        nonCorporate: 'Non Corporate',
        individuals: 'Individuals',
        management: 'Management',
        keyOff: 'Key Officers',
        marketSummary: 'Market Summary',
        message: 'Message',
        avgTransVal: 'Avg. Transaction Value',
        avgTransVal30Days: '30D Avg. Transaction Value',
        companyContact: 'Company Contact Details',
        IrContactPerson: 'IR Contact person',
        dividendHistory: 'Dividend History',
        sendMessage: 'Send a message',
        examplePhone: 'Ex: (UAE) 0097143259996',
        sending: 'Sending....',
        send: 'Send',
        mandatoryField: 'Mandatory Fields',
        // [TODO : Akila] add these to language portal
        dayChange: 'Day Change',
        dayPerChange: 'Day %Change',
        last: 'Last',
        performance: '1Y',
        subscribe: 'Subscribe',
        stopLoss: 'Stop Loss',
        stopLossAlert: 'Alert me if the share price declines  below this value.',
        unsubscribe: 'Unsubscribe',
        unSubscribeEmail: 'Unsubscribe from Email Alerts',
        highVolume: 'High Volume',
        highVolumeAlert: 'Alert me if today\'s Volume goes above 30Day average volume',
        volumeTarget: 'Volume Target',
        volumeTargetAlert: 'Alert me if today\'s Volume goes above this Volume',
        negativePctChg: 'Negative percentage change',
        negativePctChgAlert: 'Alert me if the today\'s %change declines more than this value.',
        positivePctChg: 'Positive percentage change',
        positivePctChgAlert: 'Alert me if the today\'s %change advances above this value.',
        priceTarget: 'Price Target',
        priceTargetAlert: 'Alert me if the share price reaches this value.',
        dailyTradeSummary: 'Daily Trade Summary',
        dailyTradeSummaryAlert: 'Send me today\'s trade summary, at the end of the trading day.',
        emailSighUp: 'Sign up for Email Alerts',
        emailAddress: 'Enter your email address here',
        changeSubscription: 'Please re-select and enter the email address if u wish to make any changes in the subscription.',
        unsubscriptionMessage: 'By entering your email address below, you will no longer receive email alerts for any of the subscriptions listed above.If you do sunsubscribe from email alerts,you can subscribe at anytime by using the sign up process again.',
        signUpMessage: 'To receive notifications via email, enter your email address and select at least one subscription below.After submitting your information, you will receive an email.You must click the link to activate your subscription.You can sign up for additional subscriptions at any time.',
        creditRatings: 'Credit Ratings',
        ratingAgency: 'Rating Agency',
        longTermRatings: 'Long-Term Rating',
        shortTermRatings: 'Short-Term Rating',
        outlook: 'Outlook',
        ratingsAffirmed: 'Ratings Affirmed',
        analystRecommendation: 'Analyst Recommendation',
        analystCoverage: 'Analyst Coverage',
        firm: 'Firm',
        analyst: 'Analyst',
        contactInfo: 'Contact Information',
        phone: 'Phone',
        highestVol: 'Highest Volume',
        lowestVol: 'Lowest Volume',
        highestPrice: 'Highest Price',
        lowestPrice: 'Lowest Price',
        highestDayPctChg: 'Highest Day %Change',
        lowestDayPctChg: 'Lowest Day %Change',
        chgPeriod: 'Change & % Change for the period',
        sharePriceOneYear: 'Share Price - 1 Year',
        oneYr: '1 Year',
        lastTradedPrice: 'Last Traded Price',
        fiftyTwoWksRange: '52 Weeks Range',
        stockInformation: 'Stock Information',
        others: 'Others',
        mutualFunds: 'Mutual Funds',
        ownersByCountry: 'Owners By Country',
        ownersByType: 'Owners By Type',
        version: 'Version',
        hostVersion: 'Host Version',
        copyRight: '2017 DirectFN. All rights reserved',
        announcemnt: 'Announcement',
        completion: 'Completion',
        rightsEnd: 'Rights End',
        disclosures: 'Disclosures',
        BoardOfDirectors: 'Board of Directors',
        ratings: 'Rating',
        general: 'General',
        irDepartment: 'IR Department',
        presentations: 'IR Presentation',
        analystView: 'Analyst View',
        shareHolderAffairs: 'Shareholders Affairs',
        annualReports: 'Annual Reports',
        factSheet: 'Fact Sheet ',
        ownersAndSubsidiaries: 'Owners & Subsidiaries',
        sharePrice: 'Share Price',
        chg: 'Change',
        pctChg: '% Change',
        historicalPrices: '1Y Historical Prices',
        IrContactEmail: 'IR Contact',
        contactUs: 'Contact Us',
        fills: 'Fills',
        inThousands: 'In Thousands',
        auditors: 'Auditors',
        noOfSharesOutstanding: 'No. of Shares Outstanding',
        back: 'Back',
        simpleMovingAvg: 'Simple moving average (14)',
        expMovAvg: 'Exponential Moving Average (14)',
        RSI: 'RSI',
        generalCompanyEvents: 'General Company Events',
        shareholderMeeting: 'Shareholder Meeting',
        actions: 'Actions',
        extraordinaryShareholderMeeting: 'Extraordinary Shareholder Meeting',
        economyEvents: 'Economy Events',
        boardOfDirectorsMeeting: 'Board of Directors Meeting',
        vacations: 'Vacations',
        speech: 'Speech',
        reports: 'Reports',
        purchasingManagersIndex: 'Purchasing Managers\' Index (PMI)',
        ordinaryAndExtraordinaryGeneralMeeting: 'Ordinary and Extraordinary General Meeting',
        quantitativeEasing: 'Quantitative Easing (QE)',
        unemployment: 'Unemployment',
        oil: 'Oil',
        interestRate: 'Interest Rate',
        inflation: 'Inflation',
        grossDomesticProduct: 'Gross Domestic Product (GDP)',
        tradingOfInstrumentsEvents: 'Trading of Instruments Events',
        trade: 'Trade',
        firstName: 'First Name',
        lastName: 'Last Name',
        submit: 'Submit',
        reset: 'Reset',
        totalIncome: 'Total Income',
        netFinance: 'Net Financing',
        totDeposits: 'Total Deposits',
        valueInMillion: 'values are in {{1}} millions',
        SimpleMovingAverage10: 'Simple Moving Average (10)',
        SimpleMovingAverage25: 'Simple Moving Average (25)',
        SimpleMovingAverage50: 'Simple Moving Average (50)',
        ExponentialMovingAverage10: 'Exponential Moving Average (10)',
        ExponentialMovingAverage25: 'Exponential Moving Average (25)',
        ExponentialMovingAverage50: 'Exponential Moving Average (50)',
        ATR: 'ATR',
        ConStatementFinPos: 'Consolidated Statement of Financial Position',
        ConStatemenCashFlows: 'Consolidated Statement of Cash Flows',
        ConStatementIncome: 'Consolidated Statement of Income',
        companyName: 'Company Name',
        dataStartingFrom: 'Data Starting From',
        overview: 'Overview',
        latestDiv: 'Lastest Dividend',
        divPerShr: 'per share',
        exDate: 'Ex Date',
        payDate: 'Pay Date',
        twleMonDiv: '12-month dividend',
        totRtnTwleMon: 'Total Return 12-month',
        totRtn3Y: 'Total Return 3 years ',
        annDivYld: 'Annual Dividend Yield',
        annDiv: 'Annual Dividend',
        footNoteFirst: 'Calculated based on the accumulated dividends paid (after listing) during the last 12 month and the latest trade price',
        footerNoteSecond: 'Calculated assuming dividends reinvested scenario',
        footerNoteThird: 'Calculated based on close price of the latest traded date. For the current year – based on the close price of the latest traded date',
        perRtn: 'Periodic Return',
        rtnReinvDiv: 'Return on Reinvested Dividends',
        annTotRtn: 'Annualized Total Return',
        years: 'Years',
        footerNoteFourth: 'Based on end of day close price',
        year: 'Year',
        annRtn: 'Annual Return',
        rtnDiv: 'Dividend Return',
        divAdj: 'Dividend (adj.)',
        cumRtn: 'Cumulative Return',
        cumRtn1Y: 'Cumulative Return 1 year',
        cumRtn3Y: 'Cumulative Return  3 year',
        cumRtn5Y: 'Cumulative Return  5 year',
        apply: 'Apply',
        custom: 'Custom',
        values: 'Values',
        Y1: 'Year 1',
        Y3: 'Year 3',
        Y5: 'Year 5',
        Monday: "Mo",
        Tuesday: "Tu",
        Wednesday: "We",
        Thursday: "Th",
        Friday: "Fr",
        Sunday: "Su",
        Saturday: "Sa",
        docPrw: 'Document Preview',
        providedBy: 'Provided by',
        tickerCode: 'Ticker Code',
        numOfShares: 'Number Of Shares',
        shareGraph: 'Share Graph',
        shareData: 'Share Data',
        performanceStk: 'Performance',
        interval: 'Interval:',
        indicators: 'Indicators',
        chartType: 'Chart Type',
        dateRange: 'Date Range',
        print: 'Print',
        detach: 'Detach',
        downloadExcel: 'Download Excel',
        chartItems: 'Chart Items',
        indices: 'Indices',
        peers: 'Peers',
        addVol: 'Add Volume',
        announ: 'Announcements',
        corAct: 'Corporate Actions',
        oneDay: '1D',
        fiveDay: '5D',
        oneMonth: '1M',
        twoYear: '2Y',
        fiveYear: '5Y',
        maxYear: 'Max',
        disclosureTitle: 'Press releases are available from 23 March, 2021',
        ask: 'Ask',
        shareSeries: 'Share Series',
        dailyChange: 'Daily change +/-',
        dailyChangeptg: 'Daily change %',
        prevClose: 'Previous close',
        Week52Ptg: '52wk %',
        totRelease: 'Total Releases',
        enterKeyword: 'Enter Keyword',
        compName: "ALJAZIRA"
      },
      messages: {
        dataNotAvailable: 'Data not available',
        loading: 'Loading...',
        noUpcomingEvents: 'No upcoming events',
        noEvents: 'No events',
        selectFromToDates: 'Please specify both From and To dates',
        financialsColumnMsg: 'FY ends in {{1}}. Values are in {{2}} millions except per share data',
        financialsColumnMsgThousand: 'FY ends in {{1}}. Values are in {{2}} Thousands',
        financialsColumnMsgFYEnds: 'FY ends in {{1}}.',
        mailSentSuccess: 'Email sent successfully',
        mailSentFail: 'Email sent failed',
        invalidName: 'Name is invalid',
        invalidEmail: 'Email is invalid',
        invalidPhone: 'Phone number is invalid',
        invalidMessage: 'Message content is invalid',
        setValue: 'Please set a value here',
        selectOneAlertType: 'Please select at least One Alert type',
        noTranslation: 'No translation. Click to see the Arabic announcement',
        eventCalenderMsg: 'All future dates are subject to change',
        noEventsForThisMonth: 'No events for this month',
        remindThatDay: 'Remind on same day',
        remindOneDayBefore: 'Remind one day before',
        remindOneWeekBefore: 'Remind one week before',
        remindOneMonthBefore: 'Remind one month before',
        downloadIntraday: 'Download all intraday trades'
      }
    }
  };

});